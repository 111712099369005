// Dependencies
import React from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import {internalization} from '../../constants.js'

// Constants
import { DETAIL_TYPE_ONE_CODES, CAROUSEL_ENABLED_CODES, NOT_PASSIVES } from "../../constants";

// Actions
import { getBCDetail, createAssetResource, destroyAsset, editAsset, getLifeInsuranceDetail, createAsset} from "../../actions/balance";
import { createActualPerformance, editActualPerformance, getInputsActualPerformance } from "../../actions/life_insurance"
import { getElementDetail } from "../../actions/ownership";

import {getAlert} from "../../actions/alerts";
import { getDocDetail } from "../../actions/documents";
import { getContact, destroyAssignAssetByResourceable } from "../../actions/contact";
import { getTenant } from "../../actions/tenant";
import {destroyImageAsset} from "../../actions/image";
import {deleteLinkedResource} from "../../actions/linked_resources";
import {getLinkForm} from "../../actions/formblock";
import {setEnabledAssignContacts, setEnabledAssignDocuments} from "../../actions/session";
import {paginateTab} from "../../actions/tabs_pagination";

// Components
import Loading from "../../components/loading";
import DetailTypeOne from "./details/detailTypeOne";
import ActionSheet from "../../components/actionSheet";
import FloatButton from "../../components/floatButton";
import DetailTypeTwo from "./details/detailTypeTwo";
import Icon from "../../components/icons";
import AssetsResourcesModal from "../../screens/balance/details/assetsResourcesModal";
import AlertModal from "../../components/alertModal";
import Modal from "../../components/modal";
import ViewList from '../../components/viewList'
import LoadFileButton from '../../components/loadFileButton'


// Helpers
import ActionSheetOnPressAction from "../../helpers/actionSheetOnPressAction";
import { canManage, canRead } from "../../helpers/permits";

class BalanceDetailScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      options_action_sheet: [],
      showModal: false,
      showSuccesModal: false,
      showDeleteModal: false,
      modalData: {},
      showImageModal: false,
      imagesToZoom: [],
      showModalAddContact: false,
      assignData: {},
      showNavigateModal: false,
      asset: {},
      deletedAssets: [],
      loadImage: false,
      currentSlide: 0,
      showSuccesImages: false,
      showSuccessDetroyImage: false, 
      destoyedSuccess: false, 
      showDeleteModalDocument: {},
      showUnlink: false,
      modal_body: "",
      modal_title: "",
      detail_type: "",
      modal_url: "",
      modal_action: "",
      linkedDestroyModal: false,
      updateTableEvolution: false,
      inputsActualPerformance: {},
      successDeleteImage: false,
      oldSlide: 0,
      activeSlide: 0,
      activeSlide2: 0,
      changeIndexSlide: false,
      updated: false,
      tab_index: 0,
      ownershipLinked: false
    };

    this.actionSheet = React.createRef();
  }

 componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.props.getBCDetail(
          this.props.authentication_token,
          () => this._success(),
          () => this._error(),
          this._getUrlVars()["url"],
          this._getUrlVars()["id"]
        );

      this.props.getBreadcrumbs({
        url:
          this.props.history.location.pathname +
          this.props.history.location.search,
        assetUrl: this._getUrlVars()["url"],
      });
    }
  }
  _actionSheetPressed(item) {
    item = Object.assign(item, {resource_id: this.props.balance_detail.identifier.id})
    let response = ActionSheetOnPressAction(
      item,
      this.props.balance_detail,
      this.props,
      () => this._deleteAsset(item.url, item.method)
    );

    this.actionSheet.current.show();
    if (response.status)
      this.setState({ showModal: true, modalData: response.modalData });
     
  }

  _getActionSheetMethod(item, state){
    switch(item.code){
      case "add_images":
        return () => { this.setState({loadImage: true}, this.inputNode.click()); this.actionSheet.current.show();};
      case "add_document":
        return () => {this._openDocumentsOptions(item,state) }
      case "add_contact":
        return () => {this._openContactsOptions(item,state) }
      case "add_alerts":
        return () => { this._actionSheetPressed(item)};
      case "link_trust": {
        return () => this._openLinkTrust(item, state)
      }
      case "link_corporation": {
        return () => this._openLinkCorporation(item, state)
      }
      case "link_account": {
        return () => this._openLinkAccount(item, state)
      }
      default: 
        return () => { this._actionSheetPressed(item) }
    }
    
  }

  _openLinkTrust(item, state) {
    this.actionSheet.current.show();
    this.setState({
    showModalAddContact: true,
      assignData: {
        type:'trust',
        action_to_refresh: true,
        url_from: this.props.history.location.state ? this.props.history.location.state.url : "",
        id_from : this.props.history.location.state && this.props.history.location.state.id !== undefined
              ? this.props.history.location.state.id
              : "",
        url: "ownership_structure/trusts",
        id: "trust",
        resourceable_id: this.props.balance_detail.identifier.resourceable_id,
        resourceable_type: this.props.balance_detail.identifier.resourceable_type,
        location: this.props.location,
        backAction: () => this.setState({showModalAddContact: false}),
        fetch: () => this._fetch(),
        assigned_contacts: this._getAssignedIdsFromDetailTabsLinked("Trust","linked"),
        messageAlert: "Go back",
        modal: true,
        special_type: true,
        specialAssign: (saveIds,type, data, interacted_elements) => this._linkOwnership(saveIds, 'trusts', this._getAssignedIdsFromDetailTabsLinked("Trust","linked"), interacted_elements)
        
      }
    })
  }

   
    _openLinkAccount(item, state) {
    this.actionSheet.current.show();
    this.setState({
    showModalAddContact: true,
      assignData: {
        type:'account',
        action_to_refresh: true,
        url_from: this.props.history.location.state ? this.props.history.location.state.url : "",
        id_from : this.props.history.location.state && this.props.history.location.state.id !== undefined
              ? this.props.history.location.state.id
              : "",
        url: "ownership_structure/accounts",
        id: "account",
        resourceable_id: this.props.balance_detail.identifier.resourceable_id,
        resourceable_type: this.props.balance_detail.identifier.resourceable_type,
        location: this.props.location,
        backAction: () => this.setState({showModalAddContact: false}),
        fetch: () => this._fetch(),
        assigned_contacts: this._getAssignedIdsFromDetailTabsLinked("Account","linked"),
        messageAlert: "Go back",
        modal: true,
        special_type: true,
        specialAssign: (saveIds,type, data, interacted_elements) => this._linkOwnership(saveIds, 'accounts', this._getAssignedIdsFromDetailTabsLinked("Account","linked"), interacted_elements)
        
      }
    })

    }
    _openLinkCorporation(item, state) {
    this.actionSheet.current.show();
    this.setState({
    showModalAddContact: true,
      assignData: {
        type:'trust',
        action_to_refresh: true,
        url_from: this.props.history.location.state ? this.props.history.location.state.url : "",
        id_from : this.props.history.location.state && this.props.history.location.state.id !== undefined
              ? this.props.history.location.state.id
              : "",
        url: "ownership_structure/corporations",
        id: "trust",
        resourceable_id: this.props.balance_detail.identifier.resourceable_id,
        resourceable_type: this.props.balance_detail.identifier.resourceable_type,
        location: this.props.location,
        backAction: () => this.setState({showModalAddContact: false}),
        fetch: () => this._fetch(),
        assigned_contacts: this._getAssignedIdsFromDetailTabsLinked("Corporation","linked"),
        messageAlert: "Go back",
        modal: true,
        special_type: true,
        specialAssign: (saveIds,type, data, interacted_elements) => this._linkOwnership(saveIds, 'corporations', this._getAssignedIdsFromDetailTabsLinked("Corporation","linked"), interacted_elements)
        
      }
    })
  }

  _linkOwnership(ids, type, previously_assigned, interacted_elements) {    
    this.props.editAsset(
        this.props.authentication_token,
        () => {this._success('linked'); this.setState({showModalAddContact: false, ownershipLinked: true})},
        (responseJSON) => this._error(responseJSON),
        this.props.balance_detail.identifier.url+""+this.props.balance_detail.identifier.resourceable_id,
        "PUT",
        {[""+this.props.balance_detail.form.edit_asset.resource_key]: {"linked_existent":{[type]:interacted_elements}}, "id":this.props.balance_detail.identifier.resourceable_id}
      );
    // 

  }

  _openDocumentsOptions(item, state) {
    var that = this;
    let optionsActionSheet = []
    optionsActionSheet.push({
      type: "icon button",
      iconName: item.code.includes("add") ? "Close" : item.code.includes("edit") ? "Pencil" : item.code.includes("show") ? "Eye" : "Garbage",
      message: item.title,
      onClick: () => this._actionSheetPressed(item),
    })
    this.props.enabled_assign_documents && 
    optionsActionSheet.push({
      type: "icon button",
      message: this.props.internalization.buttons.assign_document,
      iconName: "Assign",
      onClick: () => {
        this.actionSheet.current.show();
        this.setState({
          showModalAddContact: true, 
          assignData: {
            type:'document',
            action_to_refresh: true,
            url_from: this.props.history.location.state ? this.props.history.location.state.url : "",
            id_from : this.props.history.location.state && this.props.history.location.state.id !== undefined
                  ? this.props.history.location.state.id
                  : "",
            url: "documents/documents?section_list=true&resourceable_type="+this.props.balance_detail.identifier.resourceable_type,
            id:"document",
            resourceable_id: this.props.balance_detail.identifier.resourceable_id,
            resourceable_type: this.props.balance_detail.identifier.resourceable_type,
            location: this.props.location,
            backAction: () => this.setState({showModalAddContact: false}),
            fetch: () => this._fetch('document'),
            assigned_contacts: this._getAssignedIdsFromDetailTabs("documents"),
            messageAlert: "Go back",
            modal: true
          }
        })
      }
    })

    this.setState({
      options_action_sheet: [
        ...optionsActionSheet,
        {
          type: "noIcon button",
          message: this.props.internalization.buttons.cancel,
          close: true,
          danger: true,
          onClick: () =>  {this._showActionSheetContent(state)}
        },
      ],
      loadingImages: false,
      showModal:false
    });
  };

  _getAssignedIdsFromDetailTabs(type){
    if(this.props.balance_detail && this.props.balance_detail.tablesv2 !== undefined && this.props.balance_detail.tablesv2[type] !== undefined){
      try{
        return this.props.balance_detail.tablesv2[type].all_ids
      }catch(e){
        return []
      }
      
    }else{
      return []
    }
  }

  _getAssignedIdsFromDetailTabsLinked(class_name, type){
    if(this.props.balance_detail && this.props.balance_detail.tablesv2 !== undefined && this.props.balance_detail.tablesv2[type] !== undefined){
      try{
        let rows = this.props.balance_detail.tablesv2[type].rows
        return rows.map(r => r.filter(e => e.key == "class_name")[0].value === class_name && r.filter(e => e.key == "id")[0].value).filter(e => e !== false) 
      }catch(e){
        return []
      }
      
    }else{
      return []
    }
  }

  _openContactsOptions(item, state) {
    var that = this;
    let optionsActionSheet = []
    optionsActionSheet.push({
      type: "icon button",
      iconName: item.code.includes("add") ? "Close" : item.code.includes("edit") ? "Pencil" : item.code.includes("show") ? "Eye" : "Garbage",
      message: item.title,
      onClick: () => this._actionSheetPressed(item),
    })
    this.props.enabled_assign_contacts && 
    optionsActionSheet.push({
      type: "icon button",
      message: this.props.internalization.buttons.assign_contact,
      iconName: "Assign",
      onClick: () => {
        this.actionSheet.current.show();
        this.setState({
          showModalAddContact: true,
          assignData: {
            type:'contact',
            action_to_refresh: true,
            url_from: this.props.history.location.state ? this.props.history.location.state.url : "",
            id_from : this.props.history.location.state && this.props.history.location.state.id !== undefined
                  ? this.props.history.location.state.id
                  : "",
            url: "balance_principal/contacts",
            id:"contact",
            resourceable_id: this.props.balance_detail.identifier.resourceable_id,
            resourceable_type: this.props.balance_detail.identifier.resourceable_type,
            location: this.props.location,
            backAction: () => this.setState({showModalAddContact: false}),
            fetch: () => this._fetch('contact'),
            assigned_contacts: this._getAssignedIdsFromDetailTabs("contacts"),
            messageAlert: "Go back",
            modal: true
            
          }
          })
      }
    })

    this.setState({
      options_action_sheet: [
        ...optionsActionSheet,
        {
          type: "noIcon button",
          message: this.props.internalization.buttons.cancel,
          close: true,
          danger: true,
          onClick: () =>  {this._showActionSheetContent(state)}
        },
      ],
      loadingImages: false,
      showModal:false
    });
  };



  _showActionSheetContent(state) {
    let that = this;
    let optionsActionSheet = [];
    this.props.balance_detail.actions.map(item => {
      return optionsActionSheet.push({
        type: "icon button",
        code: item.code,
        iconName: item.code === "add_alerts" ? "Notification" : item.code.includes("add")
          ? "Close"
          : item.code.includes("edit")
          ? "Pencil"
          : item.code.includes("show")
          ? "Eye"
          : item.code.includes("link")
          ? "LinkChain"
          : "Garbage",
        message: item.title,
        onClick: this._getActionSheetMethod(item, state)
      });
    });

    this.setState({
      options_action_sheet: [
        ...optionsActionSheet,
        {
          type: "noIcon button",
          message: this.props.internalization.buttons.cancel,
          close: true,
          danger: true
        }
      ]
    });

    setTimeout(function() {
      that.actionSheet.current.show();
    }, 100);
  }

  _showContactSuccess(responseJSON, url, idMain = undefined, edit_mode = false) {

    if(responseJSON){
      this.setState({
        url_contact: url,
        firstImages: {
          images: responseJSON.form.edit_contact.inputs.filter(
            e => e.id === "image"
          )
        },
        formData: {
          images: responseJSON.form.edit_contact.inputs.filter(
            e => e.id === "image"
          )
        },
        showModal: true,
        resources: responseJSON.assets,
        modalData: {
          idMain: idMain !== undefined ? idMain : this.props.balance_detail.identifier.id,
          id: responseJSON.identifier.id,
          form: responseJSON.form.edit_contact,
          can_manage: responseJSON.form.edit_contact.can_manage,        
          resource_key: responseJSON.form.edit_contact.resource_key,
          url: url,
          title: responseJSON.title,
          show_title: responseJSON.show_title,
          edit_title: responseJSON.edit_title,        
          method: "PUT",
          code: "edit_contact",
          edit_mode: edit_mode
        }
      });

    }else{
      alert("Access error")
    }
  }

  _showStakeHolder(url, edit_mode = false) {

    this.props.getElementDetail(
      this.props.authentication_token,
      url,
      (responseJSON) => this._successElementDetail(responseJSON, edit_mode),
      () => this._error()
    );
  }

  _successElementDetail(responseJSON, edit_mode = false) {

    let modalData = {}
    if(responseJSON.hasOwnProperty("owner_beneficiary")){
      modalData = {
        edit_title: responseJSON.owner_beneficiary.actions[0].title,
        show_title: true,
        resource_type: responseJSON.owner_beneficiary.identifier.resourceable_type,
        idMain: responseJSON.owner_beneficiary.identifier.id,
        form: responseJSON.owner_beneficiary.form.edit.inputs,
        resource_key: responseJSON.owner_beneficiary.form.edit.resource_key,
        url:
          "balance_principal/deferred_assets/owner_beneficiaries/" +
          responseJSON.owner_beneficiary.identifier.id,
        method: responseJSON.owner_beneficiary.actions[0].method,
        code: responseJSON.owner_beneficiary.actions[0].code,
        can_manage: responseJSON.owner_beneficiary.form.edit.can_manage,
        code: "edit_stake_holder",
        documents: [],
        contacts: [],
        edit_mode: edit_mode,
        associated_elements: [{add_document: []}, {add_contact: []}]   
      }
    }else{
      let title = responseJSON.ownership.actions[0].title
       if(this.props.balance_detail.identifier.resourceable_type === "RealEstate" && responseJSON.ownership.identifier.resourceable_type == "StakeHolder"){
        title = responseJSON.ownership.actions[0].title.split(" ")[0] + " " +responseJSON.ownership.title
       }
      modalData = {
        edit_title: title,
        show_title: true,
        resource_type: responseJSON.ownership.identifier.resourceable_type,
        idMain: responseJSON.ownership.identifier.id,
        form: responseJSON.ownership.form.edit.inputs,
        resource_key: responseJSON.ownership.form.edit.resource_key,
        url:
          responseJSON.ownership.identifier.url +
          "/" +
          responseJSON.ownership.identifier.id,
        method: responseJSON.ownership.actions[0].method,
        code: responseJSON.ownership.actions[0].code,
        can_manage: responseJSON.ownership.form.edit.can_manage,
        code: "edit_stake_holder",
        documents: responseJSON.ownership.documents,
        contacts: responseJSON.ownership.contacts,
        edit_mode: edit_mode,
        associated_elements: [{add_document: responseJSON.ownership.form.add_document}, {add_contact: responseJSON.ownership.form.add_contact}]      
      };  
    };
    this.setState({ showModal: true, modalData: modalData });
  }  
  _showTenantSuccess(responseJSON, url, edit_mode = false){
    if(responseJSON){
      this.setState({
        url_contact: url,
        showModal: true,
        resources: responseJSON.assets,
        modalData: {
          idMain: responseJSON.identifier.id,
          id: responseJSON.identifier.id,
          form: responseJSON.form.edit.inputs,
          can_manage: responseJSON.form.edit.can_manage,        
          resource_key: responseJSON.form.edit.resource_key,
          url: url,
          title: responseJSON.title,
          show_title: responseJSON.show_title,
          edit_title: responseJSON.edit_title,        
          method: "PUT",
          code: "edit_tenant",
          documents: responseJSON.documents,
          contacts: responseJSON.contacts,
          edit_mode: edit_mode, 
          associated_elements: [{add_document: responseJSON.form.add_document}, {add_contact: responseJSON.form.add_contact}]
        }
      });

    }else{
      alert("Access error")
    }
  }

  _showTenant(url, edit_mode = false) {
    this.props.getTenant(
      this.props.authentication_token,
      responseJSON => this._showTenantSuccess(responseJSON.tenant, url, edit_mode),
      () => alert("error"),
      url + "?resourceable_id="+this.props.balance_detail.identifier.resourceable_id + "&resourceable_type="+ this.props.balance_detail.identifier.resourceable_type
    );
  }
  _showContact(url, idMain = undefined, edit_mode = false) {
    
    this.props.getContact(
      this.props.authentication_token,
      responseJSON => this._showContactSuccess(responseJSON, url, idMain, edit_mode),
      () => alert("error"),
      url + "?resourceable_id="+this.props.balance_detail.identifier.resourceable_id + "&resourceable_type="+ this.props.balance_detail.identifier.resourceable_type
    );
  }

  _unlinkContact(contact){
    this.setState({
      showUnlink: true,
      modal_title: this.props.internalization.modals['contact'].unlink.title,
      modal_body: this.props.internalization.modals['contact'].unlink.body,
      modal_action: () => this.props.destroyAssignAssetByResourceable(
              this.props.authentication_token,
              () => {this.setState({
                destoyedSuccess: true,
                showUnlink:false,  
                modal_title: this.props.internalization.modals['contact'].unlinked.title,
                modal_body: this.props.internalization.modals['contact'].unlinked.body,
                modal_action: () => {this._fetch('contact')}

              })},
              () => {alert("Something went wrong")},
              contact.id,
              this.props.balance_detail.identifier.resourceable_id,
              this.props.balance_detail.identifier.resourceable_type, 
              '/balance_principal/contacts_assets/',
              'contact_id'
            )
    })
  }

  _successDetailDoc(responseJSON, url, edit_mode = false){
    this.setState({
      url_contact: url,
      showModal: true,
      resources: responseJSON.assets,
      modalData: {
        idMain: this.props.balance_detail.identifier.id,
        id: responseJSON.identifier.id,
        form: responseJSON.form.edit_document.inputs,
        resource_key: responseJSON.form.edit_document.resource_key,
        can_manage: responseJSON.form.edit_document.can_manage,        
        url: url,
        title: responseJSON.title,
        show_title: responseJSON.show_title,
        edit_title: responseJSON.edit_title,        
        method: responseJSON.actions[0].method,
        code: responseJSON.actions[0].code,
        linked: responseJSON.assets.length > 1,
        edit_mode: edit_mode
      }
    });
  }

  

  _showDocument(url, edit_mode = false) {

    this.props.getDocDetail(
      this.props.authentication_token,
      responseJSON => this._successDetailDoc(responseJSON, url, edit_mode),
      () => this._error(),
      url + "?resourceable_id="+this.props.balance_detail.identifier.resourceable_id + "&resourceable_type="+ this.props.balance_detail.identifier.resourceable_type

    );
  }

  _showLifeInsuranceElement(url, type, edit_mode){
    this.props.getLifeInsuranceDetail(
      this.props.authentication_token,
      responseJSON => this._successShowLifeInsuranceElement(responseJSON, url, type, edit_mode),
      () => this._error(),
      url + "?resourceable_id="+this.props.balance_detail.identifier.resourceable_id + "&resourceable_type="+ this.props.balance_detail.identifier.resourceable_type

    );    
  }

  _successShowLifeInsuranceElement(responseJSON, url, type, edit_mode = false){
    
    if(responseJSON){
      this.setState({
        url_contact: url,
        showModal: true,
        resources: responseJSON[type].linked_resources && responseJSON[type].linked_resources.data,
        modalData: {
          idMain: this.props.balance_detail.identifier.id,
          id: responseJSON[type].identifier.id,
          form: responseJSON[type].form.edit.inputs,
          resource_key: responseJSON[type].form.edit.resource_key,
          can_manage: responseJSON[type].form.edit.can_manage,        
          url: url,
          title: responseJSON[type].title,
          show_title: responseJSON[type].show_title,
          edit_title: responseJSON[type].edit_title,        
          method: responseJSON[type].actions ? responseJSON[type].actions[0].method : "",
          code: responseJSON[type].actions ? responseJSON[type].actions[0].code : "",
          edit_mode: edit_mode
        }
      });      
    }else{
      alert("Access error")
    }
  }

  _showAlert(id, edit_mode = false) {
    this.props.getAlert( this.props.authentication_token, id, (responseJSON) => this._successAlert(responseJSON, edit_mode), () => alert("error"))
  }

  _successAlert(responseJSON, edit_mode = false){
    this.setState({
      showModal: true,
      modalData: {
        idMain: this.props.balance_detail.identifier.id,
        id: responseJSON.bc_show.identifier.id,
        form: responseJSON.bc_show.form.edit_alert.inputs,
        can_manage: responseJSON.bc_show.can_manage,        
        resource_key: responseJSON.bc_show.form.edit_alert.resource_key,
        url: /alerts/+responseJSON.bc_show.identifier.id,
        title: responseJSON.bc_show.title,
        show_title: responseJSON.bc_show.show_title,
        edit_title: responseJSON.bc_show.edit_title,
        method: "PUT",
        code: "edit_alert",
        edit_mode: edit_mode
      }
    });    

  }

  _deleteAsset(url, method) {
    this.setState({
      showDeleteModal: true,
      modal_title: this.props.internalization.modals[this.state.detail_type].delete.title,
      modal_body: this.props.internalization.modals[this.state.detail_type].delete.body,
      modal_url: url,
      modal_action: () => this._successDeleteAsset(url)
    });
  }

  _successDeleteAsset(url) {
    if(url.includes("valuations")){
      window.history.replaceState({}, "bt360", this.props.location.pathname + this.props.location.search + "&code=valuations")  
    }
    this.setState({destoyedSuccess: true,
      modal_title: this.props.internalization.modals[this.state.detail_type].deleted.title,
      modal_body: this.props.internalization.modals[this.state.detail_type].deleted.body,
      modal_action: () => url.includes("valuations") ? window.location.reload() : this.props.history.replace("/balance-show", {url: this.props.balance_detail.identifier.url})
    })
  }

  _success(set_tab_key = undefined) {
    let title = "";
    if (Object.keys(this.props.balance_detail).length !== 0) {
      let title_found = this.props.balance_detail.form.edit_asset.inputs.filter(
        input => input.id === "name" || input.id === "policy_number"
      );

      title = title_found.length === 0 ? this.props.data && this.props.data.title : title_found[0].value;
    }
    let urlSliced = ""
    let languageUserAgent = (/^es/).test(this.props.language) ? 'es' : 'en';  
    if (this.props.balance_detail.actions !== undefined && this.props.balance_detail.actions !== null && this.props.balance_detail.actions.length > 0) {
      let action = this.props.balance_detail.breadcrumb_code == "alternative_assets" ? this.props.balance_detail.actions[1] : this.props.balance_detail.actions[0]
      let urlLength = action.url.length;
      let indexOfBar = action.url.lastIndexOf("/");
      let charactersToSlice = urlLength - indexOfBar;
      urlSliced = action.url.slice(
        0,
        -charactersToSlice
      );

    }
    if(this.props.balance_detail.breadcrumb_code === "alternative_assets"){
      urlSliced = "balance_principal/custodians"
      let financial_url_back = "/balance-show?url=balance_principal/financial_assets"      
      if (this.props.balance_detail.historical_date){
        financial_url_back = financial_url_back + "&historical_date="+this.props.balance_detail.historical_date_breadcrumb        
      }
        this.props.setSpecialParent([
          {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
         {title:  internalization[languageUserAgent].models.financial_assets, code: "financial_assets", url: financial_url_back},
         {title:  title, code: "custodian_show", url: "/balance-detail?url=balance_principal/alternative_assets&id="+this.props.balance_detail.identifier.id}
         ])
    }
    if(this.props.balance_detail.breadcrumb_code === "custodians"){
      urlSliced = "balance_principal/custodians"
      let custodian_url_back = "/balance-show?url=balance_principal/financial_assets&code=custodian"
      let financial_url_back = "/balance-show?url=balance_principal/financial_assets"      
      if (this.props.balance_detail.historical_date){
        custodian_url_back = custodian_url_back + "&historical_date="+this.props.balance_detail.historical_date_breadcrumb
        financial_url_back = financial_url_back + "&historical_date="+this.props.balance_detail.historical_date_breadcrumb        
      }
        this.props.setSpecialParent([
          {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
         {title:  internalization[languageUserAgent].models.financial_assets, code: "financial_assets", url: financial_url_back},
         {title:  internalization[languageUserAgent].models.custodians, code: "custodians", url: custodian_url_back},
         {title:  title, code: "custodian_show", url: "/balance-detail?url=balance_principal/custodians&id="+this.props.balance_detail.identifier.id}
         ])
    }
    if(this.props.balance_detail.breadcrumb_code === "liability_addepars"){
      urlSliced = "balance_principal/liability_addepars"
      let custodian_url_back = "/balance-show?url=balance_principal/financial_assets&code=liabilities_addepar"
      let financial_url_back = "/balance-show?url=balance_principal/financial_assets"   
      if (this.props.balance_detail.historical_date){
        custodian_url_back = custodian_url_back + "&historical_date="+this.props.balance_detail.historical_date_breadcrumb
        financial_url_back = financial_url_back + "&historical_date="+this.props.balance_detail.historical_date_breadcrumb        
      }
        this.props.setSpecialParent([
          {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
         {title:  internalization[languageUserAgent].models.financial_assets, code: "financial_assets", url: financial_url_back},
         {title:  internalization[languageUserAgent].models.liabilities, code: "liability_addepars", url: custodian_url_back},
         {title:  title, code: "liability_addepar_show", url: "/balance-detail?url=balance_principal/liability_addepars&id="+this.props.balance_detail.identifier.id}
         ])
    }
    if(this.props.balance_detail.breadcrumb_code === "accounts"){
      urlSliced = "balance_principal/accounts"
      let current_breadcrumb_localstorage = localStorage.getItem("special_parent")
      let custodian_url_back = "/balance-show?url=balance_principal/financial_assets&code=custodian"
      let financial_url_back = "/balance-show?url=balance_principal/financial_assets"
      if (this.props.balance_detail.historical_date){
        custodian_url_back = custodian_url_back + "&historical_date="+this.props.balance_detail.historical_date_breadcrumb
        financial_url_back = financial_url_back + "&historical_date="+this.props.balance_detail.historical_date_breadcrumb
      }
      if(current_breadcrumb_localstorage){
        
        try{
          current_breadcrumb_localstorage = JSON.parse(current_breadcrumb_localstorage)  
        }catch(e){
           current_breadcrumb_localstorage = []
        }
        if (current_breadcrumb_localstorage.length === 0){
          current_breadcrumb_localstorage = [
            {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
           {title:  internalization[languageUserAgent].models.financial_assets, code: "financial_assets", url: financial_url_back},
           {title:  internalization[languageUserAgent].models.custodians, code: "custodians", url: custodian_url_back},
           ]
        }
        
      }else{
        current_breadcrumb_localstorage = [
          {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
         {title:  internalization[languageUserAgent].models.financial_assets, code: "financial_assets", url: financial_url_back},
         {title:  internalization[languageUserAgent].models.custodians, code: "custodians", url: custodian_url_back},
         ]

        // current_breadcrumb_localstorage = []
      }

      this.props.setSpecialParent(current_breadcrumb_localstorage)
    }
    let detail_type;
    let reg = new RegExp(DETAIL_TYPE_ONE_CODES);
    console.log("aaaa", this.props.location.search)
    if (this.props.insurance_user || (this.props.balance_detail.actions !== undefined && this.props.balance_detail.actions !== null && this.props.balance_detail.actions.length > 0) || this.props.balance_detail.historical_date) {
      if (
        reg.test(
          this.props.history.location.state !== undefined
            ? this.props.history.location.state.url
            : urlSliced
        )
      ) {
        this.props.getBreadcrumbs({
          assetUrl: urlSliced,
          url:
            this.props.history.location.pathname +
            this.props.history.location.search,
          assetTitle: title
        });
      } else {
        if(this.props.history.location && this.props.history.location.state){
          this.props.getBreadcrumbs({
            assetUrl: this.props.history.location.state.url,
            url:
              this.props.history.location.pathname +
              this.props.history.location.search,
            assetTitle: this.props.balance_detail.title
          });
        }
      }

    }

    let regNotPasive = new RegExp(NOT_PASSIVES);
    detail_type = regNotPasive.test(this.props.history.location.state !== undefined ? this.props.history.location.state.url : urlSliced) ? "asset" : "liability"
    this.setState({
      loading: false,
      deletedAssets: [],
      showDeleteModalDocument:{},
      detail_type: detail_type,
      destoyedSuccess: false,
      linkedDestroyModal: false,
      showModal:false,
      modalData: {},
      modal_action: "",
      successDeleteImage: false,
      updated: !this.state.updated
    });
    let code_selected = this._getUrlVars()["code"]
    if(set_tab_key === undefined && code_selected !== undefined){
      set_tab_key = code_selected
      this.props.location.search = this.props.location.search.replace("&code="+code_selected, "")
      window.history.replaceState({}, "bt360", this.props.location.pathname + this.props.location.search)
    }

    if(set_tab_key !== undefined){
      let to_go = 0
      if(set_tab_key === "contact" || set_tab_key === "contacts"){
        to_go = this._getTabIndexFromCode('contacts')
      }

      if(set_tab_key === "alert" || set_tab_key === "alerts"){
        to_go = this._getTabIndexFromCode('alerts')
      }
      if(set_tab_key === "document" || set_tab_key === "documents"){
        to_go = this._getTabIndexFromCode('documents')
      }
      if(set_tab_key === "tenant" || set_tab_key === "tenants"){
        to_go = this._getTabIndexFromCode('tenants')
      }
      if(set_tab_key === "valuation" || set_tab_key === "valuations"){
        to_go = this._getTabIndexFromCode('valuations')
      }

      if(set_tab_key === "image" || set_tab_key === "images"){
        to_go = this._getTabIndexFromCode('main')  
        if(this.props.balance_detail && this.props.balance_detail.identifier && ["AlternativeAsset", "Liability", "LifeInsurance"].includes(this.props.balance_detail.identifier.resourceable_type)){
           to_go = this._getTabIndexFromCode('images')  
        }
        
      }
      if(["beneficiary_life_insurance", "owner_life_insurance","owner_beneficiary", "insured", "stake_holders", "stake_holder"].includes(set_tab_key)){
        to_go = this._getTabIndexFromCode('stake_holders')
      }
      if(set_tab_key === "linked" || set_tab_key === "trust" || set_tab_key === "corporation"){
        to_go = this._getTabIndexFromCode('linked')
      }
      if(to_go === -1 && this.state.tab_index !== 0){
        this.setState({tab_index: 0})
      }else{
        if(this.state.tab_index !== to_go && to_go !== -1){
          this.setState({tab_index: to_go})    
        }
        
      }
      
    }

    if(this.props.location && this.props.location.state && this.props.location.state.is_ownership_linked){
      this.setState({ownershipLinked: true})
    }

  }

  _getTabIndexFromCode(code) {
    return this.props.balance_detail.tabs.findIndex((tab) => tab.code === code)
  }

  _error() {
    this.setState({loading: false});
    alert("error");
  }

  _successCreateAssetResource(edit, key, modalData) {
    let key_fix = ["owner_beneficiary"].includes(key) ? modalData.code === "edit_owner" ? "owner_life_insurance" : "beneficiary_life_insurance" : key

    if(key === "contact"){
      this.props.setEnabledAssignContacts()
    }

    if(key === "document"){
      this.props.setEnabledAssignDocuments()
    }
    if(this.props.balance_detail.identifier.resourceable_type === "RealEstate" && key_fix === "stake_holder"){
      this.setState({ modal_title: edit ? this.props.internalization.modals["owner"].updated.title : this.props.internalization.modals["owner"].created.title, modal_body: edit ? this.props.internalization.modals["owner"].updated.body : this.props.internalization.modals["owner"].created.body, showSuccesModal: true, loading:false, resources: [] });
    }else{
      this.setState({ modal_title: edit ? this.props.internalization.modals[key_fix].updated.title : this.props.internalization.modals[key_fix].created.title, modal_body: edit ? this.props.internalization.modals[key_fix].updated.body : this.props.internalization.modals[key_fix].created.body, showSuccesModal: true, loading:false, resources: [] });  
    }
    
  }

  _errorCreateAssetResource(responseJSON = undefined) {
    this.setState({ loading: false });
    if (responseJSON && responseJSON.hasOwnProperty("error")) {
      // alert(responseJSON.error);
      this.setState({
        showSuccesModal: true,
        loading: false,
        modal_title: "Error",
        modal_body: responseJSON.error,
        modal_action: () => this.setState({ showSuccesModal: false })
      });
    } else {
      alert("Error");
    }
  }

  _uploadResource(data) {
    let promises = [];

    this.setState({ showModal: false, loading: true });
    
    if(this.state.deletedAssets.length > 0){

      let key = this.state.modalData.resource_key
      if(this.state.modalData.code === 'edit_contact'){
       
        this.state.deletedAssets.map((item,index) => {
          let promiseMap = new Promise((resolve, reject) => {
            this.props.destroyAssignAssetByResourceable(
              this.props.authentication_token,
              () => {resolve()},
              () => {reject()},
              this.state.modalData.id,
              item.resourceable_id,
              item.resourceable_type, 
              '/balance_principal/contacts_assets/',
              'contact_id'
            );
          })
          promises.push(promiseMap)
        })
        Promise.all(promises).then((completed) => this._fetch('contact'))
      }else if(this.state.modalData.code === 'edit_owner' || this.state.modalData.code === 'edit_beneficiary'){
        key = "owner_life_insurance"
        this.state.deletedAssets.map((item,index) => {
          let promiseMap = new Promise((resolve, reject) => {
            this.props.deleteLinkedResource(this.props.authentication_token,"OwnerBeneficiary", this.state.modalData.id,item.class_name === "Trust" ? "Trust" : "Corporation",item.id, () => resolve(), () => reject())
          })
          promises.push(promiseMap)
        })
        Promise.all(promises).then((completed) => this._fetch(this.state.modalData.code))
      }
      else {
        this.state.deletedAssets.map((item,index) => {
          let promiseMap = new Promise((resolve, reject) => {
            this.props.destroyAssignAssetByResourceable(
              this.props.authentication_token,
              () => resolve(),
              () => reject(),
              this.state.modalData.id,
              item.resourceable_id,
              item.resourceable_type, 
              '/documents/documents_assets/',
              'document_id'
            );
          })
          promises.push(promiseMap)
        })
        Promise.all(promises).then((completed) => this._fetch(this.state.modalData.code))
      }
      this.setState({ modal_title:  this.props.internalization.modals[key].updated.title, modal_body: this.props.internalization.modals[key].updated.body, showSuccesModal: true, loading:false, resources: [] });
      
    }

    this.props.createAssetResource(
      this.props.authentication_token,
      () => this._successCreateAssetResource(this.state.modalData.method === "PUT", this.state.modalData.resource_key, this.state.modalData),
      (responseJSON) => this._errorCreateAssetResource(responseJSON),
      this.state.modalData.url,
      this.state.modalData.method,
      data
    );
  }

  _differType() {
    let reg = new RegExp(DETAIL_TYPE_ONE_CODES);
    if (this.props.balance_detail && this.props.balance_detail.actions !== undefined) {
      let value = this.props.history.location.state !== undefined
          ? this.props.history.location.state.url
          : this.props.history.location.search
      
      if (value === 'balance_principal/receivable_accounts' || this.props.balance_detail.breadcrumb_code === "receivable_account"){
        return false
      }
      else{
        return reg.test(value);  
      }
      
    }
  }

  _hideModal = e => {
    
    if (
      e.target.className === "modal-wrapper" ||
      (e.target.closest(".modal-header") !== null &&
        e.target.closest(".modal-header").className.includes("modal-header"))
    ) {
      this.setState({
        imagesToZoom: [],
        showImageModal: false,
        changeIndexSlide: false
      });
    }
  };

  componentDidMount() {
    this._fetch()
  }

  _getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
  }

  _getURL(){
    return this.props.history.location.state === undefined ? Object.keys(this.props.balance_detail).length > 0 ? this.props.balance_detail.identifier !== undefined ? this.props.balance_detail.identifier.url : this._getUrlVars()["url"] : this._getUrlVars()["url"] : this.props.history.location.state.url
  }

  _getID(){
    return this.props.history.location.state === undefined ? Object.keys(this.props.balance_detail).length > 0 ? this.props.balance_detail.identifier !== undefined ? this.props.balance_detail.identifier.id : this._getUrlVars()["id"] : this._getUrlVars()["id"] : this.props.history.location.state.id
  }

  _fetch(set_tab_key = undefined){
    this.props.getBCDetail(
      this.props.authentication_token,
      () => this._success(set_tab_key),
      () => this._error(),
      this._getURL(),
      this._getID()
    );    
  }

  _goToResource(asset) {
    this.setState({
      showNavigateModal: true,
      asset: asset
    });
 
  }


  _destroyAssets(url, state){
    let allAssets = state.resources
    let deletedAssets = state.deletedAssets
    let setKey

    if(url.resourceable_id !== undefined){
      setKey = "resourceable_id"
    }else {
      setKey = "id"
    }

    let searchAssets = allAssets.filter(deleted => deleted[setKey] === url[setKey])
    
    if(searchAssets.length > 1){
      allAssets.map((item, index) => {
        if(item.id === url.id && item.ownerable_type === url.ownerable_type){
          allAssets.splice(index, 1)
        }
        })
    }else {
      allAssets = allAssets.filter(deleted => deleted[setKey] !== url[setKey])
    }

    deletedAssets.push(url)
    this.setState({
      resources: allAssets,
      deletedAssets: deletedAssets
    })
  }

  _destroyAssetAssociated(modalData){
    let resource_key = ["owner_beneficiary"].includes(modalData.resource_key) ? modalData.code === "edit_owner" ? "owner_life_insurance" : "beneficiary_life_insurance" : modalData.resource_key
    if(this.props.balance_detail.identifier.resourceable_type === "RealEstate" && resource_key === "stake_holder"){
      resource_key = "owner"
    }
    this.setState({
      showDeleteModal: true,
      modal_title: this.props.internalization.modals[resource_key].delete.title,
      modal_body: this.props.internalization.modals[resource_key].delete.body,
      modal_url: modalData.url,
      modal_action: () => this._successDestroyAssetAssociated(modalData)
    });
  }

  _successDestroyAssetAssociated(modalData){
    let resource_key = ["owner_beneficiary"].includes(modalData.resource_key) ? modalData.code === "edit_owner" ? "owner_life_insurance" : "beneficiary_life_insurance" : modalData.resource_key    
    if(this.props.balance_detail.identifier.resourceable_type === "RealEstate" && resource_key === "stake_holder"){
      resource_key = "owner"
    }
    this.setState({destoyedSuccess: true,
      modal_title: this.props.internalization.modals[resource_key].deleted.title,
      modal_body: this.props.internalization.modals[resource_key].deleted.body,
      modal_action: () => this._fetch(modalData.resource_key)
    })

  }

  _navigateToAsset(state){
    let asset = state.asset
    let url = 
      asset.url_recover ? 
        asset.url_recover ? 
          asset.url.collection 
          : asset.url.collection
            : asset.url
    if(url.indexOf('ownership_structure') !== -1){
      window.location.href = asset.url.resource || url
    }else {
      let url_asset = url.slice()
      url_asset = url_asset.split("/")
      url_asset.pop()
      url_asset = url_asset.join("/")
      if(asset.resourceable_type === "Tenant"){
        url_asset = url.slice()
        url_asset = url_asset.split("/")
        let id = url_asset[url_asset.length - 1]
        url_asset.pop()
        url_asset = url_asset.join("/")
        this.props.history.push("balance-detail?url="+url_asset+"&id="+id, {
          url: url_asset,
          id: id
        });
      }
      else{
        this.props.history.push("balance-detail?url="+url_asset+"&id="+asset.resourceable_id, {
          url: url_asset,
          id: asset.resourceable_id
        });  
      }
      
      setTimeout(function(){
        window.location.reload()
      },200)
    }
  }

  _deleteLinkedResource(item){
    this.props.deleteLinkedResource(this.props.authentication_token, this.props.balance_detail.identifier.resourceable_type, this.props.balance_detail.identifier.id, item.class_name, item.id, () => this._showSuccessDeleteLinkModal(), () => alert("error"))
  }

  _showSuccessDeleteLinkModal(){
    this.setState({
      showDeleteModalDocument: {},
      showModal:false,
      showSuccesModal: true,
      linkedDestroyModal:false,
      modal_title: this.props.internalization.modals.linked.deleted.title,
      modal_body: this.props.internalization.modals.linked.deleted.body,
      modal_action: () => this._fetch()
    })    
  }

  _showDeleteLinkModal(item){
    this.setState({
      showDeleteModalDocument: {},
      showModal:false,
      linkedDestroyModal: true,
      modal_title: this.props.internalization.modals.linked.delete.title,
      modal_body: this.props.internalization.modals.linked.delete.body,
      modal_action: () => this._deleteLinkedResource(item)
    })    
  }  

  _navigateToAssetFromLinkedResource(item){

    this.setState({loading: true})
    let url = item.url_recover;
    if(url === undefined && item.url) {url = item.url.resource}
    if(url.indexOf('ownership_structure') !== -1){
      window.location.href = item.url.resource
    }else {
      let url_item = url.slice()
      url_item = url_item.split("/")
      url_item.pop()
      url_item = url_item.join("/")
      this.props.history.push("balance-detail?url="+url_item+"&id="+item.id, {
        url: url_item,
        id: item.id
      });
      setTimeout(function(){
        window.location.reload()
      },200)
    }
  }

  _successGetImage(){
    this._success("image")
    this.setState({showSuccesImages: true})
  }

  _getImage(image){

    let resourceKey = this.props.balance_detail.form.edit_asset.resource_key
  
      this.props.editAsset(
      this.props.authentication_token,
      () => this._successGetImage(), 
      () => this._error(),
      this.props.balance_detail.identifier.url+this.props.balance_detail.identifier.id,
      "PUT",
      {[resourceKey]: image.formDataImage}
    );
  
    
  }

  //Carousel arrows//
  _next() {
      this.slider1.slickNext();
      this._changeIndexSlide()
  }

  _previous() {
    this.slider1.slickPrev();
    this._changeIndexSlide()
  }
  //@Carousel arrows//

  _changeIndexSlide(){
    this.setState({changeIndexSlide: true})
  }

  _successDeleteImg(){
    this.setState({showSuccessDetroyImage: true,successDeleteImage: true, showImageModal: false, changeIndexSlide: false })
  }

  _deleteImage(image){

    this.props.destroyImageAsset(this.props.authentication_token, () => this._successDeleteImg(), () => "", image[0].id)
  }

  _deleteDocument(item, url){
    this.setState({
      showDeleteModalDocument: {
        title: this.props.internalization.alerts.delete_asset,
        subtitle: item.linked ? this.props.internalization.alerts.delete_document_linked : this.props.internalization.alerts.delete_asset_confirmation,
        url: url,
        method: "DELETE"
      }
    })
  }

  _successDeleteAssetDocument(){
    this.setState({
      showDeleteModalDocument: {},
      showModal:false,
      destoyedSuccess: true,
      modal_title: this.props.internalization.modals.document.deleted.title,
      modal_body: this.props.internalization.modals.document.deleted.body,
      modal_action: () => this._fetch()
    })
  }

  _getDataActualPerformance(actualPerformance, action, title){

    if(action === "create"){
      this.props.createActualPerformance(
        this.props.authentication_token,
        () => {this.setState({
          updateTableEvolution: true,
          modal_body: `Actual Performance Year ${title} created Successfully`,
          modal_action: () => this._successDeleteAsset()}); 
          this._fetch()},
        () => console.log("error"),
        actualPerformance)
    }else {
      this.props.editActualPerformance(
        this.props.authentication_token,
        () => {this.setState({
          updateTableEvolution: true,
          modal_body: `Actual Performance Year ${title} updated Successfully`,
          modal_action: () => this._successDeleteAsset()}); 
          this._fetch()},
        () => console.log("error"),
        actualPerformance)
    }
  }

   getInputsActualPerformance(url){
    this.props.getInputsActualPerformance(
      this.props.authentication_token,
      url,
      responseJSON => {this.setState({inputsActualPerformance: responseJSON})},
      () => console.log("error"),
      )
  }

  deleteImageCarousel(state){
    let getIndex = ""
    let getId = ""
    if(state.changeIndexSlide ){
      getIndex = state.activeSlide
    }else {
      getIndex = state.currentSlide
    }

    getId = state.imagesToZoom[getIndex].id

    this.props.destroyImageAsset(this.props.authentication_token, () => this._successDeleteImg(), () => "", getId)
  }

  openValuation(element) {
    var that = this;
    let optionsActionSheet = [];
    let date_minimum = undefined; 
    let resource_key = undefined; 
    try {
      date_minimum =
        this.props.balance_detail && this.props.balance_detail.form && this.props.balance_detail.form.edit_asset && this.props.balance_detail.form.edit_asset.inputs &&
        this.props.balance_detail.form.edit_asset.inputs.filter(
          (e) => e.id === "date_bought"
        );
      if (date_minimum.length > 0) {
        date_minimum = date_minimum[0].value;
      } else {
        date_minimum = undefined;
      }
    } catch (e) {
      date_minimum = undefined;
    } 
    if (element.edit) {
      optionsActionSheet.push({
        type: "icon button",
        iconName: "Pencil",
        message: "Edit",
        onClick: () => {
          that.props.history.push("/balance-edit", {
            form: element.inputs,
            resource_key: 'valuation',
            url: element.url,
            method: "PUT",
            code: "valuation",
            date_minimum: date_minimum,
            go_back: true
          });
          that.actionSheet.current.show();
        }
      });
    }
    if (element.destroy) {
      optionsActionSheet.push({
        type: "icon button",
        iconName: "Garbage",
        message: "Delete",
        onClick: () => {
          this._deleteAsset(
            "balance_principal/valuations/" + element.id,
            "DELETE"
          );

          that.actionSheet.current.show();
        }
      });
    }
    if (optionsActionSheet.length > 0) {
      this.setState({
        options_action_sheet: [
          ...optionsActionSheet,
          {
            type: "noIcon button",
            message: this.props.internalization.buttons.cancel,
            close: true,
            danger: true
          }
        ]
      });
      setTimeout(function() {
        that.actionSheet.current.show();
      }, 100);
    }
  }

  paginateTab(params, success, error) {
    params["resourceable_id"] = this.props.balance_detail.identifier.resourceable_id
    params["resourceable_type"] = this.props.balance_detail.identifier.resourceable_type
    this.props.paginateTab(this.props.authentication_token, params, () => {this.setState({update_force: true});success()}, () => error(), true)
  }

  render() {
    let enable_carousel = new RegExp(CAROUSEL_ENABLED_CODES);
    let url = "";
    let method = "";
    if (this.props.balance_detail && this.props.balance_detail.actions !== undefined && this.props.balance_detail.actions !== null && this.props.balance_detail.actions.length > 0) {
      let action_delete = this.props.balance_detail.actions.filter(
        action =>
          action.code === "delete_asset" ||
          action.code === "delete_valuation" ||
          action.code === "add_valuation"
      );
      if(action_delete.length > 0){
        url = action_delete[0].url;
        method = action_delete[0].method;  
      }
      
    }
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      beforeChange: (current, next) =>
        this.setState({ oldSlide: current, activeSlide: next }),
      afterChange: current => this.setState({ activeSlide2: current })
    };
    let key_detail = this.props.balance_detail && this.props.balance_detail.identifier !== undefined ? (""+this.props.balance_detail.identifier.resourceable_type+"_"+this.props.balance_detail.identifier.resourceable_id) : ""+Math.random()
    console.log(this.props.balance_detail)
    return (      
      <>
        {this.state.loading && <Loading text={this.props.internalization.loading}  show={this.state.loading} />}

        <div id="balanceDetailDiv" className="main-wrapper detail">
        
        {
          !this.state.loading && this.props.balance_detail &&
          <>
          
          {this._differType() ? (
            <DetailTypeOne
              key={key_detail}
              screen_step_index={this.state.tab_index}
              handleTabChange={(index) => this.setState({tab_index: index})}
              colorGrey={true}
              fetch={() => this._fetch()}
              data={this.props.balance_detail}
              errors={this.props.errors}
              showContact={(url, edit_mode) => this._showContact(url, undefined, edit_mode)}
              unlinkContact={(contact) => this._unlinkContact(contact)}
              showTenant={(url, edit_mode) => this._showTenant(url, edit_mode)}
              showDocument={(url, edit_mode) => this._showDocument(url, edit_mode)}
              showLifeInsuranceElement={(url,type, edit_mode) => this._showLifeInsuranceElement(url, type, edit_mode)}
              showAlert={(id, edit_mode) => this._showAlert(id, edit_mode)}
              history={this.props.history}
              setImagesToZoom={(images, currentSlide) =>
                this.setState({ imagesToZoom: images, showImageModal: true, currentSlide: parseInt(currentSlide, 10) })
              }
              showStakeHolder={(url, edit_mode) =>  this._showStakeHolder(url, edit_mode)}              
              carousel_enabled={this.props.balance_detail.identifier !== undefined && enable_carousel.test(this.props.balance_detail.identifier.url)}
              deleteImage={(image) => this._deleteImage(image)}
              internalization={this.props.internalization}
              navigateTo={(item) => this._navigateToAssetFromLinkedResource(item)}
              deleteLinkedResource={(item) => this._showDeleteLinkModal(item)}
              insurance_user={this.props.insurance_user == true ? true : !canManage(this.props.permits, "rest_assets")}
              successDeleteImage={this.state.successDeleteImage}
              language={this.props.user.language}
              openValuation={(element) => this.openValuation(element)}
              location={this.props.location}
              setSpecialParent={(special_parent) => this.props.setSpecialParent(special_parent)}
              paginateTab={(params,success, error) => this.paginateTab(params, success, error)}

            />
          ) : (
            // <div></div>
            <DetailTypeTwo
              key={key_detail}
              data={this.props.balance_detail}
              screen_step_index={this.state.tab_index}
              handleTabChange={(index) => this.setState({tab_index: index})}              
              errors={this.props.errors}
              showContact={(url, edit_mode) => this._showContact(url, undefined, edit_mode)}
              unlinkContact={(contact) => this._unlinkContact(contact)}              
              showDocument={(url, edit_mode) => this._showDocument(url, edit_mode)}
              showLifeInsuranceElement={(url,type, edit_mode) => this._showLifeInsuranceElement(url, type, edit_mode)}
              showAlert={(id, edit_mode) => this._showAlert(id, edit_mode)}
              history={this.props.history}
              showStakeHolder={(url, edit_mode) =>  this._showStakeHolder(url, edit_mode)}              
              deleteImage={(image) => this._deleteImage(image)}
              carousel_enabled={this.props.balance_detail.identifier !== undefined && enable_carousel.test(this.props.balance_detail.identifier.url)}
              internalization={this.props.internalization}
              navigateTo={(item) => this._navigateToAssetFromLinkedResource(item)}
              deleteLinkedResource={(item) => this._showDeleteLinkModal(item)}   
              getDataActualPerformance={(actualPerformance, action, title) => this._getDataActualPerformance(actualPerformance, action, title)}
              identifier={this.props.balance_detail.identifier}
              insurance_user={this.props.insurance_user == true ? true : !canManage(this.props.permits, "rest_assets")}
              getInputsActualPerformance={(url) => this.getInputsActualPerformance(url)}
              inputsActualPerformance={this.state.inputsActualPerformance}
              successDeleteImage={this.state.successDeleteImage}
              language={this.props.user.language}
              openValuation={(element) => this.openValuation(element)}   
              location={this.props.location} 
              paginateTab={(params,success, error) => this.paginateTab(params, success, error)}                        
            />
          )}
        </>
        }

          {this.props.balance_detail && this.props.balance_detail.actions !== undefined && this.props.balance_detail.actions !== null && this.props.balance_detail.actions.length > 0 && (
            <div>
              <ActionSheet
                ref={this.actionSheet}
                render={this.state.options_action_sheet}
                show={true}
              />
              <FloatButton
                children={
                  <Icon
                    name="Close"
                    color="white"
                    iconBig={true}
                    className="wrapper-icon-floatButton"
                    close={false}
                  />
                }
                action={() => this._showActionSheetContent(this.state)}
              />
            </div>
          )}

          {this.state.showModal === true && (
            <AssetsResourcesModal
              language={this.props.user.language}
              identifier={this.props.balance_detail.identifier}
              colorGrey={true}
              data={this.state.modalData}
              errors={this.props.errors}
              closeModal={e => {
                if (
                  e.target.className === "modal-wrapper" ||
                  (e.target.closest(".modal-header") !== null &&
                    e.target.closest(".modal-header").className.includes("modal-header"))
                ) {
                  this.setState({ showModal: false, resources:{}, deletedAssets: [] });
                }
              }}
              uploadResource={data => this._uploadResource(data)}
              resources={this.state.resources}
              goToResource={asset => this._goToResource(asset)}
              polymorphic={
                this.state.modalData.resource_key === "contact"
                  ? "resourceable_"
                  : "ownerable_"
              }
              showDocument={url => this._showDocument(url)}
              showContact={(url, idMain) => this._showContact(url, idMain)}
              resourceToSelectDestroy={true}
              resourceDestroyAction={url => this._destroyAssets(url,this.state)}
              destroyAssetAssociated= {() => this._destroyAssetAssociated(this.state.modalData)}
              deleteDocument={(url) => this.state.modalData.resource_key === "document" ? this._deleteDocument(this.state.modalData, this.state.modalData.url) : ""}
              buttons={this.props.internalization.buttons}
              showModalSessionExpire={(time_left, diffMins, checkTime) => this.props.showModalSessionExpire(time_left, diffMins, () => checkTime())}
              getLinkForm = {(url, success_fromblock, error_fromblock) => this.props.getLinkForm(url, this.props.authentication_token, (responseJSON) => success_fromblock(responseJSON), () => error_fromblock("error"))}
              categorieOptions={this.props.balance_detail.form}
              successSendFiles={() => this._fetch()}
            />
          )}
          {this.state.showNavigateModal && (
          <AlertModal
            isShowed={this.state.showNavigateModal}
            title={this.props.internalization.alerts.navigate_to_asset}
            msg={this.props.internalization.alerts.navigate_to+" "+this.state.asset.name}          
            action={() => {
              this._navigateToAsset(this.state)
            }}
            textButton={this.props.internalization.buttons.ok}
            textButtonAlertColor={false}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={true}
            actionCancelButton={() => {
              this.setState({
                showNavigateModal: false
              });
            }}
          />
        )}
          {this.state.showSuccesModal && (
            <AlertModal
              isShowed={this.state.showSuccesModal}
              title={this.state.modal_title}
              msg={this.state.modal_body}
              action={() => {
                this.setState({
                  showSuccesModal: false
                });
                this._fetch(this.state.modalData.resource_key)
              }}
              textButton={this.props.internalization.buttons.ok}
            />
          )}
          {this.state.showDeleteModal && (
            <AlertModal
              isShowed={this.state.showDeleteModal}
              title={this.state.modal_title}
              msg={this.state.modal_body}
              action={() => {
                this.props.destroyAsset(
                  this.props.authentication_token,
                  () => this.state.modal_action(),
                  () => alert("error"),
                  this.state.modal_url,
                  "DELETE"
                );
                this.setState({
                  showDeleteModal: false
                });
              }}
              textButton={this.props.internalization.buttons.ok}
              textButtonAlertColor={true}
              cancelButton={true}
              cancelTextButton={this.props.internalization.buttons.cancel}
              cancelButtonAlertColor={false}
              actionCancelButton={() => {
                this.setState({
                  showDeleteModal: false
                });
              }}
            />
          )}
          {this.state.showUnlink && (
            <AlertModal
              isShowed={this.state.showUnlink}
              title={this.state.modal_title}
              msg={this.state.modal_body}
              action={() => {
                this.state.modal_action()
              }}
              textButton={this.props.internalization.buttons.ok}
              textButtonAlertColor={true}
              cancelButton={true}
              cancelTextButton={this.props.internalization.buttons.cancel}
              cancelButtonAlertColor={false}
              actionCancelButton={() => {
                this.setState({
                  showUnlink: false
                });
              }}
            />
          )}
          {Object.keys(this.state.showDeleteModalDocument).length > 0 && (
            <AlertModal
              isShowed={Object.keys(this.state.showDeleteModalDocument).length > 0}
              title={this.props.internalization.modals.document.delete.title}
              msg={this.props.internalization.modals.document.delete.body}
              action={() => {
                !this.state.loading &&
                this.setState({
                  showDeleteModal: false,
                  loading: true,
                }, () => this.props.destroyAsset(
                  this.props.authentication_token,
                  () => this._successDeleteAssetDocument(),
                  () => alert("error"),
                  this.state.showDeleteModalDocument.url,
                  this.state.showDeleteModalDocument.method
                ));
              }}
              textButton="Yes"
              textButtonAlertColor={true}
              cancelButton={true}
              cancelTextButton="Cancel"
              cancelButtonAlertColor={false}
              actionCancelButton={() => {
                this.setState({
                  showDeleteModalDocument: {}
                });
              }}
            />
          )}
          {this.state.showImageModal === true && (
            <Modal
              isShowed={this.state.showImageModal}
              modalType="zoom-images"
              showBackButton={true}
              backAction={e => this._hideModal(e)}
              buttonText={this.props.internalization.buttons.back}
              showDeleteButton={true}
              deleteAction={() => {this.deleteImageCarousel(this.state)}}
            >
              
                <Slider {...settings}
                      ref={slider => (this.slider1 = slider)}
                      initialSlide={this.state.currentSlide}
                >
                {this.state.imagesToZoom.map((img, index) => {
                  return (
                    <img
                      id={"img-carousel-"+img.id}
                      name={img.id}
                      key={index}
                      className="img-carousel"
                      src={img.url}
                      alt=""
                    />
                  );
                })}
              </Slider>

                <div
                  onClick={() => this._previous()}
                  className="arrow-container-left"
                >
                  <Icon
                    name="ChevronArrow"
                    orientation="left"
                  />
                </div>
                <div
                  onClick={() => this._next()}
                  className="arrow-container-right"
                >
                  <Icon
                    name="ChevronArrow"
                    orientation="right"
                  />
                </div>
            </Modal>
          )}
          {
            this.state.showModalAddContact && 
            <Modal isShowed={this.state.showModalAddContact}>
              <ViewList
                   assign={true}
                   assignData={this.state.assignData}
                />
            </Modal>
          }

          {
          
            <LoadFileButton loadMultiple={true} showAsset={true} data={{code: "add_photo"}} inputRef={node => this.inputNode = node} action={(image) => this._getImage(image)}/>
            
          }
          {this.state.updateTableEvolution && (
            <AlertModal
              isShowed={this.state.updateTableEvolution}
             
              msg={this.state.modal_body}
              action={() => {
                this.setState({
                  updateTableEvolution: false
                });
              }}
              textButton={this.props.internalization.buttons.ok}
            />
          )}
          {
            this.state.showSuccessDetroyImage && (
            <AlertModal
              isShowed={this.state.showSuccessDetroyImage}
              title={this.props.internalization.modals.deleted_image.title}
              msg={this.props.internalization.modals.deleted_image.body}
              action={() => {
                this.setState({
                  showSuccessDetroyImage: false
                });
                this._fetch("image")
              }}
              textButton={this.props.internalization.buttons.ok}
            />
          )}

          {this.state.showSuccesImages && (
            <AlertModal
              isShowed={this.state.showSuccesImages}
              title={this.props.internalization.modals.added_image.title}
              msg={this.props.internalization.modals.added_image.body}
              action={() => {
                this.setState({
                  showSuccesImages: false
                });
                this._fetch("image")
              }}
              textButton={this.props.internalization.buttons.ok}
            />
          )}
          {this.state.destoyedSuccess && (
            <AlertModal
              isShowed={this.state.destoyedSuccess}
              title={this.state.modal_title}
              msg={this.state.modal_body}
              action={() => {
                  this.state.modal_action()
              }}
              textButton={this.props.internalization.buttons.ok}
            />
          )}
          {
            this.state.linkedDestroyModal &&
            <AlertModal
              isShowed={this.state.linkedDestroyModal}
              title={this.state.modal_title}
              msg={this.state.modal_body}
              action={() => {
                  this.state.modal_action()
              }}
              textButton={this.props.internalization.buttons.ok}
              textButtonAlertColor={true}
              cancelButton={true}
              cancelTextButton={this.props.internalization.buttons.cancel}
              cancelButtonAlertColor={false}
              actionCancelButton={() => {
              this.setState({
                linkedDestroyModal: false
              });
            }}
            />
          }
          {this.state.ownershipLinked && (
            <AlertModal
              isShowed={this.state.ownershipLinked}
              title={"Linked resource selected"}
              msg={"Linked resource successfully "}
              action={() => {
                this.setState({ownershipLinked: false})
                // this.props.history.push("wealth-map", {"is_ownership_linked": true, "id": this.props.balance_detail.identifier.resourceable_id, class: this.props.balance_detail.identifier.resourceable_type});
              }}
              textButton={this.props.internalization.buttons.ok}
            />
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    balance_detail: state.balance.balance_detail,
    authentication_token: state.session.authentication_token,
    documents_show: state.documents.documents_show,
    documents_detail: state.documents.documents_detail,
    internalization: state.session.internalization,
    errors: state.session.errors,
    enabled_assign_documents: state.session.enabled_assign_documents,
    enabled_assign_contacts: state.session.enabled_assign_contacts,
    user: state.session.user, 
    insurance_user: state.session.insurance_user,
    permits: state.session.permits,   
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteLinkedResource: (authentication_token, type_1, id_1, type_2, id_2, success, error) => dispatch(deleteLinkedResource(authentication_token, type_1, id_1, type_2, id_2, success, error)),
    getAlert:(authentication_token, id, success, error) => dispatch(getAlert(authentication_token, id, success, error)),
    getBCDetail: (authentication_token, success, error, url, id) =>
      dispatch(getBCDetail(authentication_token, success, error, url, id)),
    createAssetResource: (authentication_token, success,error,url,method,attributes) =>dispatch(createAssetResource(authentication_token,success,error,url,method,attributes)),
    destroyAsset: (authentication_token, success, error, url, method) =>
      dispatch(destroyAsset(authentication_token, success, error, url, method)),
    getContact: (authentication_token, success, error, url) =>
      dispatch(getContact(authentication_token, success, error, url)),
    getTenant: (authentication_token, success, error, url) =>
      dispatch(getTenant(authentication_token, success, error, url)),  
    getDocDetail: (authentication_token, success, error, url) =>
      dispatch(getDocDetail(authentication_token, success, error, url)),
    getLifeInsuranceDetail: (authentication_token, success, error, url) =>
      dispatch(getLifeInsuranceDetail(authentication_token, success, error, url)),
    destroyAssignAssetByResourceable: ( authentication_token, success, error, id, resourceable_id,resourceable_type, url, type) => dispatch( destroyAssignAssetByResourceable( authentication_token, success,error, id, resourceable_id, resourceable_type, url, type)),
    editAsset: ( authentication_token, success, error, url, method, attributes ) => dispatch( editAsset(authentication_token, success, error, url, method, attributes)
      ),
    getElementDetail: (authentication_token, url, success, error) =>
      dispatch(getElementDetail(authentication_token, url, success, error)),

    destroyImageAsset: (authentication_token, success, error, id) => dispatch(destroyImageAsset(authentication_token, success, error, id)),
    createActualPerformance: (authentication_token, success, error, data) => dispatch(createActualPerformance(authentication_token, success, error, data)),
    editActualPerformance: (authentication_token, success, error, data) => dispatch(editActualPerformance(authentication_token, success, error, data)),
    getInputsActualPerformance: (authentication_token, url, success,error) => dispatch(getInputsActualPerformance(authentication_token, url, success,error)),
    getLinkForm: (url, authentication_token, success, error) => dispatch(getLinkForm(url, authentication_token, success, error)),
    createAsset: (authentication_token,success,error,url,method,attributes) => dispatch(createAsset(authentication_token,success,error,url,method,attributes)),
    setEnabledAssignContacts: () => dispatch(setEnabledAssignContacts()),
    setEnabledAssignDocuments: () => dispatch(setEnabledAssignDocuments()),
    paginateTab: (authentication_token, params, success, error, dispatch_enabled) => dispatch(paginateTab(authentication_token, params, success, error, dispatch_enabled))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BalanceDetailScreen);
