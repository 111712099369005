import React from "react";
import { connect } from "react-redux";

// Actions
import {
  getBCShowForms,
  getBCShow,
  createAssetResource,
  destroyAsset,
  getRecentOrdersActivities,
} from "../../actions/balance";
import {
  getContact,
  destroyAssignAssetByResourceable,
} from "../../actions/contact";
import { getDocDetail } from "../../actions/documents";
import { deleteLinkedResource } from "../../actions/linked_resources";
import {setEnabledAssignContacts, setEnabledAssignDocuments} from "../../actions/session";
import {paginateTab} from "../../actions/tabs_pagination";
import {getAlert} from "../../actions/alerts";
import {postCleanNotificationsAdeppar} from '../../actions/financial_statements'
import {getFinancialStatements} from "../../actions/financial_statements";

// Components
import Loading from "../../components/loading";
import PieGraphic from "../../components/pieGraphic";
import PieHighcharts from "../../components/PieHighcharts";
import { navigateTo } from "../../helpers/navigateTo";

import ContentBlock from "../../components/contentBlock";
import TableBlock from "../../components/tableBlock";
import ActionSheet from "../../components/actionSheet";
import FloatButton from "../../components/floatButton";
import Icon from "../../components/icons";
import CustomModal from "../../components/customModal";
import AssetsResourcesModal from "../../screens/balance/details/assetsResourcesModal";
import DetailContentBlock from "../../components/detailContentBlock";
import MediaBlock from "../../components/mediaBlock";
import Modal from "../../components/modal";
import ViewList from "../../components/viewList";
import AlertModal from "../../components/alertModal";
import BoxResources from "../../components/boxResources";
import FinancialAssetsTabs from '../../components/FinancialAssetsTabs'
import Input from "../../components/input";
import SelectMultiple from "../../components/selectMultiple";

// Helpers
import ActionSheetOnPressAction from "../../helpers/actionSheetOnPressAction";
import RenderDocIcon from "../../helpers/renderDocIcon";
import { avatars } from "../../assets/resources";
import { getIconAsset } from "../../helpers/renderIconAssets";
import ContentDropdownMenu from "../../components/contentDropdownmenu";
import Table from "../../components/Table";
import Pagination from "../../components/pagination";
import TableDropdown from "../../components/TableDropdown";

const MAX_SHOW = 12;
let pieIndex = null;

class BalanceShowScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pieColorsScale: [],
      loading: true,
      balance_show_state: [],
      options_action_sheet: [],
      options_action_sheet_short_cut: [],
      active: "MTD",
      showModal: false,
      modalTitle: "",
      modalDescription: "",
      showModalForm: false,
      resources: {},
      deletedAssets: [],
      btnShowAllItems: {},
      showModalAddContact: false,
      assignData: {},
      showSuccesModal: false,
      showDeleteModal: false,
      modal_action: "",
      destoyedSuccess: false,
      showAlertAsk: false,
      linkedDestroyModal: false,
      showDeleteModalDocument: false,
      pieSelected: false,
      pieSelectedElement: -1,
      // rednerFilter
      form: [],
      modalOptions: false,
      indexRow: -1, 

      // pagination
      activityRencentOrders: [],
      showPagination: false,
      currentPage: 1,
      totalPages: 0,
      filters: {},
      step: "top10Holding",
      showNavigateModal: false,
      tab_index: 0,
      dropDownTabActive: false,
      searchText: {},
      showSecurityModal: {},
      date: ""
    };

    this.actionSheet = React.createRef();
  }

  _getModalContent(title, description) {
    this.setState({
      modalTitle: title,
      modalDescription: description,
    });
  }

  _actionSheetPressed(item, state) {
    let dropdown_code = this._getUrlVars2()["dropdown_code"]
    if(state.step === "financial_statements" && item.code == "add_alerts"){
      item.code = "add_alerts_financial_statements"
    }else if(state.step !== "financial_statements" && item.code == "add_alerts_financial_statements"){
      item.code = "add_alerts"
    }
    let response = ActionSheetOnPressAction(
      item,
      this.props.balance_show,
      this.props,
      () => this._deleteAsset(item.url, item.method)
    );

    this.actionSheet.current.show();

    if (response.status)
      this.setState({ showModalForm: true, modalData: response.modalData });
  }

  _prepareAddPlusLink(code) {
    if (this.props.balance_show.hasOwnProperty("multiple_new")) {
      let multiple_new = this.props.balance_show.multiple_new["add_" + code];
      let associated_elements = [];
      try {
        associated_elements = [
          { add_document: multiple_new["form_document"].add_document },
          { add_contact: multiple_new["form_contact"].add_contact },
        ];
        if (
          multiple_new["form_alerts"] &&
          Object.keys(multiple_new["form_alerts"]).length > 0
        ) {
          associated_elements.push({
            add_alert: multiple_new["form_alerts"].add_alerts,
          });
        }
      } catch (e) {}
      return associated_elements;
    }
    return [];
  }
  gotToAsset(item) {
    this.setState({loading: true})
    // let title = item.title.indexOf("Add") != -1 ? item.title : (this.props.internalization.buttons.add + " "+ item.title)
    this.props.history.push("balance-new", {
      title: item.title,
      modal: item.form.add_asset.modal,
      form: item.form.add_asset.inputs,
      resource_constant: item.form.add_asset.resource_constant,
      resource_key: item.form.add_asset.resource_key,
      url: item.url,
      method: "POST",
      code: "add_asset",
      from: "BalanceShowScreen",
      associated_elements: this._prepareAddPlusLink(
        item.form.add_asset.resource_key
      ),
    });
  }

  _restartActionSheet() {
    
    this._showActionSheetContent(this.state);

    this.setState({ options_action_sheet_short_cut: [] });
  }
  _showSubcategories(item) {
    let optionsActionSheet = [];
    var that = this;
    optionsActionSheet = this.state.balance_show_state.subcategory[
      item.code
    ].subcategories.map((item_subcategory) => {
      return {
        type: "icon button",
        iconName: "Close",
        message: item_subcategory.title,
        onClick: () => {
          if (item_subcategory.form !== undefined) {
            this.gotToAsset(item_subcategory);
            this.actionSheet.current.show();
            this._restartActionSheet();
          } else {
            this.props.history.push("balance-show", {
              url: item_subcategory.url,
              open_actions: true,
            });
            this.actionSheet.current.show();
            this._restartActionSheet();
          }
        },
      };
    });
    optionsActionSheet.push({
      type: "noIcon button",
      message: this.props.internalization.buttons.cancel,
      close: true,
      danger: true,
      onClick: () => item.code === "add_life_insurance" ? this.setState({ options_action_sheet_short_cut: [] }) : this._restartActionSheet(),
    });
    this.setState({ options_action_sheet: optionsActionSheet });
    // setTimeout(function () { that.actionSheet.current.show(); }, 100);
  }

  _isSubcategories(item){
    
    if(this.state.balance_show_state.hasOwnProperty("subcategory") && Object.keys(this.state.balance_show_state.subcategory).includes(item.code)){
        if(item.code === "add_life_insurance"){
          if(this.props.bulltick_insurance_agency && this.props.real_user_insurance){
            return false
          }else if(this.props.bulltick_insurance_agency && !this.props.real_user_insurance) {
            return true
          }else {
            return false
          }
        }else {
          return true
        }
    
    }else {
      return false
    }
  }

  _showActionSheetContent(state,isLifeInsurance) {
    let that = this;
    let optionsActionSheet = [];
    if(!isLifeInsurance) {
      if(state.step === "financial_statements"){
        let item_statements = state.balance_show_state.actions.filter((element) => element.code === "add_alerts" || element.code === "add_alerts_financial_statements")
        item_statements.map((item) => {
          return optionsActionSheet.push({
            type: "icon button",
            code: item.code,
            iconName: ["add_alerts", "add_alerts_financial_statements"].includes(item.code) ? "Notification" : item.code.includes("add")
              ? "Close"
              : item.code.includes("edit")
              ? "Pencil"
              : item.code.includes("show")
              ? "Eye"
              : item.code.includes("link")
              ? "LinkChain"
              : "Garbage",
            message: item.title,
            onClick: () => {
              this._isSubcategories(item) ? this._showSubcategories(item) : this._getActionSheetMethod(item, state);
            }

          });
        });
        
      }else{
        state.balance_show_state.actions.map((item) => {
          return optionsActionSheet.push({
            type: "icon button",
            code: item.code,
            iconName: ["add_alerts", "add_alerts_financial_statements"].includes(item.code) ? "Notification" : item.code.includes("add")
              ? "Close"
              : item.code.includes("edit")
              ? "Pencil"
              : item.code.includes("show")
              ? "Eye"
              : item.code.includes("link")
              ? "LinkChain"
              : "Garbage",
            message: item.title,
            onClick: () => {
              this._isSubcategories(item) ? this._showSubcategories(item) : this._getActionSheetMethod(item, state);
            }

          });
        });
        }
      }
    
   
    this.setState({
      options_action_sheet: [
        ...optionsActionSheet,
        {
          type: "noIcon button",
          message: this.props.internalization.buttons.cancel,
          close: true,
          danger: true,
        },
      ],
    });

    if(isLifeInsurance){
      this._showSubcategories({code: "add_life_insurance"})
    }
    
    setTimeout(function () {
      that.actionSheet !== undefined &&
        that.actionSheet != null &&
        that.actionSheet.current !== null &&
        that.actionSheet.current !== undefined &&
        that.actionSheet.current.show();
    }, 100);
  }

  _getActionSheetMethod(item, state) {
    console.log({item})
    switch (item.code) {
      case "add_document":
        return this._openDocumentsOptions(item,state);
      case "add_contact":
        return this._openContactsOptions(item, state);
      case "add_investment_account":
        return this._actionSheetPressed(item, state);
      case "add_alerts":
        return this._actionSheetPressed(item, state);        
      default:
        return this._actionSheetPressed(item, state);
    }
  }


  _getAssignedIdsFromDetailTabs(type){
    
    if(this.props.balance_show && this.props.balance_show.tables_addepar !== undefined && this.props.balance_show.tables_addepar[type] !== undefined){
      try{
        return this.props.balance_show.tables_addepar[type]['table'].all_ids
      }catch(e){
        return []
      }
      
    }else{
      return []
    }
  }
  _openDocumentsOptions(item, state) {
    var that = this;
    let optionsActionSheet = []
    optionsActionSheet.push({
      type: "icon button",
      iconName: item.code.includes("add") ? "Close" : item.code.includes("edit") ? "Pencil" : item.code.includes("show") ? "Eye" : "Garbage",
      message: item.title,
      onClick: () => this._actionSheetPressed(item, state),
    })
    this.props.enabled_assign_documents && 
    optionsActionSheet.push({
      type: "icon button",
      message: this.props.internalization.buttons.assign_document,
      iconName: "Assign",
      onClick: () => {
        this.actionSheet.current.show();
        this.setState({
          showModalAddContact: true, 
          assignData: {
            type:'document',
            action_to_refresh: true,
            url_from: this.props.history.location.state ? this.props.history.location.state.url : "",
            id_from : this.props.history.location.state && this.props.history.location.state.id !== undefined
                  ? this.props.history.location.state.id
                  : "",
            url: "documents/documents?section_list=true&resourceable_type="+this.props.balance_show.identifier.resourceable_type,
            id:"document",
            resourceable_id: this.props.balance_show.identifier.resourceable_id,
            resourceable_type: this.props.balance_show.identifier.resourceable_type,
            location: this.props.location,
            backAction: () => this.setState({showModalAddContact: false}),
            fetch: () => this._fetch(0,'document'),
            assigned_contacts: this._getAssignedIdsFromDetailTabs("documents"),
            messageAlert: "Go back",
            modal: true
          }
        })
      }
    })

    this.setState({
      options_action_sheet: [
        ...optionsActionSheet,
        {
          type: "noIcon button",
          message: this.props.internalization.buttons.cancel,
          close: true,
          danger: true,
        },
      ],
      loadingImages: false,
      showModalForm: false,
    });
  };


  _openContactsOptions(item, state) {
    var that = this;
    let optionsActionSheet = [];
    optionsActionSheet.push({
      type: "icon button",
      iconName: item.code.includes("add")
        ? "Close"
        : item.code.includes("edit")
        ? "Pencil"
        : item.code.includes("show")
        ? "Eye"
        : "Garbage",
      message: item.title,
      onClick: () => this._actionSheetPressed(item, state),
    });
    this.props.enabled_assign_contacts &&
      optionsActionSheet.push({
        type: "icon button",
        message: this.props.internalization.buttons.assign_contact,
        iconName: "Assign",
        onClick: () => {
          this.actionSheet.current.show();
          this.setState({
            showModalAddContact: true,
            assignData: {
              type: "contact",
              action_to_refresh: true,
            url_from: this.props.history.location.state ? this.props.history.location.state.url : "",
            id_from : this.props.history.location.state && this.props.history.location.state.id !== undefined
                  ? this.props.history.location.state.id
                  : "",
              url: "balance_principal/contacts",
              id: "contact",
              resourceable_id: this.props.balance_show.identifier
                .resourceable_id,
              resourceable_type: this.props.balance_show.identifier
                .resourceable_type,
              location: this.props.location,
              backAction: () => this.setState({ showModalAddContact: false }),
              fetch: () => this._fetch(0,'contact'),
              assigned_contacts: this._getAssignedIdsFromDetailTabs("contacts"),
              messageAlert: "Go back",
              modal: true,
            },
          });
        },
      });

    this.setState({
      options_action_sheet: [
        ...optionsActionSheet,
        {
          type: "noIcon button",
          message: this.props.internalization.buttons.cancel,
          close: true,
          danger: true,
        },
      ],
      loadingImages: false,
      showModalForm: false,
    });
  }

  _setPieColorsScale() {
    let colors =
      this.state.balance_show_state && this.state.balance_show_state.graphs &&
      this.state.balance_show_state.graphs.map((graph) =>
        graph.graph_data.data.map((element) => element.color)
      );

    this.setState({
      pieColorsScale: colors,
    });
  }

  _setPieColorsScaleFinancial(active = "MTD") {
    let colors =
      this.state.balance_show_state.graphs_select.graph[active] &&
      this.state.balance_show_state.graphs_select.graph[active].data.map(
        (element) => element.color
      );

    this.setState({
      pieColorsScale: colors,
    });
  }

  _success(set_tab_key = undefined, date = "") {
    if(this._getURL().includes("collections/")){
      this.props.getBCShowForms(
        this.props.authentication_token,
        () => {this.setState({balance_show_state: this.props.balance_show})},
        () => this._error(),
        "balance_principal/collections/home/get_inputs"
      );
    }

    let obj = {};
    Object.keys(this.props.balance_show).map((item) => {
      obj = { ...obj, [item]: { show: false, lengthItems: MAX_SHOW } };
    });
    this.setState({
      loading: false,
      balance_show_state: this.props.balance_show,
      btnShowAllItems: obj,
      destoyedSuccess: false,
      showModal: false,
      modalData: {},
      modal_action: "",
      indexRow: -1,
      pieSelected: false,
      pieSelectedElement: -1,
      modalData: {},
      date: date
    });
    window.scrollTo(0, 0);

    if (
      this.props.location.state != undefined &&
      this.props.location.state.open_actions
    ) {
      this._showActionSheetContent(this.state);
    }
    this._setPieColorsScale();

    let code_selected = this._getUrlVars2()["code"]
    let dropdown_code = this._getUrlVars2()["dropdown_code"]
    let search = this._getUrlVars2()["search"]
    let query = this._getUrlVars2()["query"]
    let historical_date = this._getUrlVars2()["historical_date"]

    if(set_tab_key === undefined && code_selected !== undefined){
      set_tab_key = code_selected
      if (date !== "" && date !== undefined && date !== null && date !== "null"){      
        let url = window.location.href.split("&")[0]; 
        window.history.replaceState({}, "bt360", url +  "&historical_date="+date+"&code="+code_selected)
      }else{
        // this.props.location.search = this.props.location.search.replace("&code="+code_selected, "")
        this.props.location.search = this.props.location.search.replace("&search="+search, "")
        this.props.location.search = this.props.location.search.replace("&query="+query, "")
        window.history.replaceState({}, "bt360", this.props.location.pathname + this.props.location.search)
      }
    }

    if((date === "" || date === undefined) && historical_date !== "" && historical_date !== undefined){
      this._fetchFromHistorical(historical_date, true)
      this.setState({date: historical_date})
      // window.history.replaceState({}, "bt360", url +  "&historical_date="+date+"&code="+code_selected)
    }
    if (dropdown_code !== undefined){
      if(this.state.balance_show_state && this.state.balance_show_state && this.state.balance_show_state.content_menu){
        let tab_index = this.getDropdownActiveIndex(dropdown_code) 
        let tab = this.state.balance_show_state.content_menu[tab_index]
        this.setState({dropDownTabActive: tab})
        this.props.getBreadcrumbs({
          url:
          this.props.history.location.pathname +
            this.props.location.search,
          assetUrl: "balance_principal/financial_assets/"+tab.code,
        });
      }


    }
    if(set_tab_key !== undefined){
      let to_go = 0
      if(set_tab_key === "contact" || set_tab_key === "contacts"){
        to_go = this._getTabIndexFromCode('contacts')
      }
      if(set_tab_key === "alert" || set_tab_key === "alerts"){
        to_go = this._getTabIndexFromCode('alerts')
      }
      if(set_tab_key === "document" || set_tab_key === "documents"){
        to_go = this._getTabIndexFromCode('documents')
      }
      if(set_tab_key === "tenant" || set_tab_key === "tenants"){
        to_go = this._getTabIndexFromCode('tenants')
      }
      if(set_tab_key === "valuation" || set_tab_key === "valuations"){
        to_go = this._getTabIndexFromCode('valuations')
      }
      if(set_tab_key === "top10Holding"){
        to_go = this._getTabIndexFromCode('top10Holding')
      } 
      if(set_tab_key === "assetClass"){
        to_go = this._getTabIndexFromCode('assetClass')
      }
      if(set_tab_key === "publicVSNonPublicSecurities"){
        to_go = this._getTabIndexFromCode('publicVSNonPublicSecurities')
      }
      if(set_tab_key === "assetType"){
        to_go = this._getTabIndexFromCode('assetType')
      } 
      if(set_tab_key === "custodian"){
        to_go = this._getTabIndexFromCode('custodian')
      }
      if(set_tab_key === "financial_statements"){
        to_go = this._getTabIndexFromCode('statements_group')
      }

      if(set_tab_key === "liabilities_addepar"){
        to_go = this._getTabIndexFromCode('liabilities_addepar')
      }
      if(set_tab_key === "alternatives"){
        to_go = this._getTabIndexFromCode('alternatives')
      }
      if(set_tab_key === "activity"){
        to_go = this._getTabIndexFromCode('activity')
      }                            
      if(set_tab_key === "image" || set_tab_key === "images"){
        to_go = this._getTabIndexFromCode('main')  
        if(this.props.balance_detail && this.props.balance_detail.identifier && this.props.balance_detail.identifier.resourceable_type === "AlternativeAsset"){
           to_go = this._getTabIndexFromCode('images')  
        }
        
      }
      if(set_tab_key === "linked"){
        to_go = this._getTabIndexFromCode('linked')
      }

      if(to_go === -1 && this.state.tab_index !== 0){
        this.setState({tab_index: 0})
      }else{
        if(this.state.tab_index !== to_go && to_go !== -1){
          this.setState({tab_index: to_go})    
        }
        
      }

      this.setState({tab_index: to_go})  
    }
    else{
      console.log("else")
      if(date){
        console.log("hay date")
      }else{
        console.log("NO hay date")
        this.setState({tab_index: 0})
      }
      
    }

  }

  _getTabIndexFromCode(code) {
    if(this.state.balance_show_state && this.state.balance_show_state.tables_addepar){
      return this.state.balance_show_state.tables_addepar.tabs.findIndex((tab) => tab.code === code)  
    }
    return 0
  }
  _error() {
    alert("error");
  }

  _getUrlVars2() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
  }
  _getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  }

  _getURL() {
    return this.props.history.location.state === undefined
      ? Object.keys(this.state.balance_show_state).length > 0 && this.props.balance_detail
        ? this.props.balance_detail.identifier.url
        : this._getUrlVars()["url"]
      : this.props.history.location.state.url;
  }

  _fetch(page, set_tab_key = undefined) {
    this.props.getBCShow(
      this.props.authentication_token,
      "",
      () => this._success(set_tab_key),
      () => this._error(),
      this._getURL()
    );
  }

  _fetchFromHistorical(date, from_url = false) {
   let temp_date = date === "Invalid date" ? "" : date
   let historical_date = this._getUrlVars2()["historical_date"]
   
   if ((date === "null" || date === null) && historical_date !== "" && historical_date !== undefined){
      this.props.location.search = this.props.location.search.replace("&historical_date="+historical_date, "")
      window.history.replaceState({}, "bt360", this.props.location.pathname + this.props.location.search)      
   }
   if (date === null || date === "null"){
    temp_date = ""
   }
   this.props.getBCShow(
      this.props.authentication_token,
      temp_date,
      () => this._success(undefined, temp_date),
      () => this._error(),
      this._getURL()
    ); 
  }
  componentDidMount() {

    let historical_date = this._getUrlVars2()["historical_date"]
    if(historical_date !== undefined && historical_date !== ""){
      this._fetchFromHistorical(historical_date);  
    }else{
      this._fetch();
    }
    
    this.props.setSpecialParent([]);
    this.props.getBreadcrumbs({
      url:
        this.props.history.location.pathname +
        this.props.history.location.search,
      assetUrl: this._getURL(),
    });

    this.props.getRecentOrdersActivities(
      this.props.authentication_token,
      "?page=1",
      responseJSON => this._successRecentOrderesActivity(responseJSON),
      () => this._error()
    )
  }

  _getURLUpdate() {
    return this.props.history.location.state === undefined
      ? this.props.history.location.pathname +
          this.props.history.location.search
      : this.props.history.location.state.url;
  }

  _goToResource(asset) {
    this.setState({
      showNavigateModal: true,
      asset: asset,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this._fetch();
      this.props.getBreadcrumbs({
        url:
          this.props.history.location.pathname +
          this.props.history.location.search,
        assetUrl: this._getUrlVars()["url"],
      });
      this.setState({dropDownTabActive: false})
    }
  }

  _uploadResource(data) {
    let promises = [];

    this.setState({ showModal: false, loading: true });

    if (this.state.deletedAssets.length > 0) {
      let key = this.state.modalData.resource_key;
      if (this.state.modalData.code === "edit_contact") {
        this.state.deletedAssets.map((item, index) => {
          let promiseMap = new Promise((resolve, reject) => {
            this.props.destroyAssignAssetByResourceable(
              this.props.authentication_token,
              () => {
                resolve();
              },
              () => {
                reject();
              },
              this.state.modalData.id,
              item.resourceable_id,
              item.resourceable_type,
              "/balance_principal/contacts_assets/",
              "contact_id"
            );
          });
          promises.push(promiseMap);
        });
        Promise.all(promises).then((completed) => this._fetch('contact'));
      } else if (
        this.state.modalData.code === "edit_owner" ||
        this.state.modalData.code === "edit_beneficiary"
      ) {
        key = "owner_life_insurance";
        this.state.deletedAssets.map((item, index) => {
          let promiseMap = new Promise((resolve, reject) => {
            this.props.deleteLinkedResource(
              this.props.authentication_token,
              "OwnerBeneficiary",
              this.state.modalData.id,
              item.class_name === "Trust" ? "Trust" : "Corporation",
              item.id,
              () => resolve(),
              () => reject()
            );
          });
          promises.push(promiseMap);
        });
        Promise.all(promises).then((completed) => this._fetch(this.state.modalData.code));
      } else {
        this.state.deletedAssets.map((item, index) => {
          let promiseMap = new Promise((resolve, reject) => {
            this.props.destroyAssignAssetByResourceable(
              this.props.authentication_token,
              () => resolve(),
              () => reject(),
              this.state.modalData.id,
              item.resourceable_id,
              item.resourceable_type,
              "/documents/documents_assets/",
              "document_id"
            );
          });
          promises.push(promiseMap);
        });
        Promise.all(promises).then((completed) => this._fetch(this.state.modalData.code));
      }

      this.setState({
        modal_title: this.props.internalization.modals[key].updated.title,
        modal_body: this.props.internalization.modals[key].updated.body,
        showSuccesModal: true,
        loading: false,
        resources: [],
      });
    }

    this.props.createAssetResource(
      this.props.authentication_token,
      () =>
        this._successCreateAssetResource(
          this.state.modalData.method === "PUT",
          this.state.modalData.resource_key,
          this.state.modalData
        ),
      () => this._errorCreateAssetResource(),
      this.state.modalData.url,
      this.state.modalData.method,
      data
    );
  }

  _errorCreateAssetResource() {
    this.setState({ loading: false });
    alert("error");
  }

  _successCreateAssetResource(edit, key, modalData) {
    let key_fix = ["owner_beneficiary"].includes(key)
      ? modalData.code === "edit_owner"
        ? "owner_life_insurance"
        : "beneficiary_life_insurance"
      : key;

      if(key === "contact"){
        this.props.setEnabledAssignContacts()
      }

      if(key === "document"){
        this.props.setEnabledAssignDocuments()
      }

    this.setState({
      modal_title: edit
        ? this.props.internalization.modals[key_fix].updated.title
        : this.props.internalization.modals[key_fix].created.title,
      modal_body: edit
        ? this.props.internalization.modals[key_fix].updated.body
        : this.props.internalization.modals[key_fix].created.body,
      showSuccesModal: true,
      loading: false,
      resources: [],
      showModalForm: false,
    });
  }

  _unlinkContact(contact){
    this.setState({
      showUnlink: true,
      modal_title: this.props.internalization.modals['contact'].unlink.title,
      modal_body: this.props.internalization.modals['contact'].unlink.body,
      modal_action: () => this.props.destroyAssignAssetByResourceable(
              this.props.authentication_token,
              () => {this.setState({
                destoyedSuccess: true,
                showUnlink:false,  
                modal_title: this.props.internalization.modals['contact'].unlinked.title,
                modal_body: this.props.internalization.modals['contact'].unlinked.body,
                modal_action: () => {this._fetch(0, 'contact')}

              })},
              () => {alert("Something went wrong")},
              contact.id,
              this.props.balance_show.identifier.resourceable_id,
              this.props.balance_show.identifier.resourceable_type, 
              '/balance_principal/contacts_assets/',
              'contact_id'
            )
    })
  }


  _btnShowAllItems(type, show, length) {
    this.setState({
      btnShowAllItems: {
        ...this.state.btnShowAllItems,
        [type]: { show: show, lengthItems: show ? length : MAX_SHOW },
      },
    });
  }

  _successDetailDoc(responseJSON, url, edit_mode) {
    this.setState({
      url_contact: url,
      showModalForm: true,
      resources: responseJSON.assets,
      modalData: {
        idMain: this.props.balance_show.identifier.id,
        id: responseJSON.identifier.id,
        form: responseJSON.form.edit_document.inputs,
        resource_key: responseJSON.form.edit_document.resource_key,
        can_manage: responseJSON.form.edit_document.can_manage,
        url: url,
        title: responseJSON.title,
        show_title: responseJSON.show_title,
        edit_title: responseJSON.edit_title,
        method: responseJSON.actions[0].method,
        code: responseJSON.actions[0].code,
        linked: responseJSON.assets.length > 1,
        edit_mode: edit_mode
      },
    });
  }

  _showDocument(url, edit_mode = false) {
    this.props.getDocDetail(
      this.props.authentication_token,
      (responseJSON) => this._successDetailDoc(responseJSON, url, edit_mode),
      () => this._error(),
      url +
        "?resourceable_id=" +
        this.props.balance_show.identifier.resourceable_id +
        "&resourceable_type=" +
        this.props.balance_show.identifier.resourceable_type
    );
  }

  _showAlert(id, edit_mode = false) {
    this.props.getAlert( this.props.authentication_token, id, (responseJSON) => this._successAlert(responseJSON, edit_mode), () => alert("error"))
  }

  _successAlert(responseJSON, edit_mode = false){
    console.log({responseJSON})
    this.setState({
      showModalForm: true,
      modalData: {
        idMain: this.props.balance_show.identifier.id,
        id: responseJSON.bc_show.identifier.id,
        form: responseJSON.bc_show.form.edit_alert.inputs,
        can_manage: responseJSON.bc_show.can_manage,        
        resource_key: responseJSON.bc_show.form.edit_alert.resource_key,
        url: /alerts/+responseJSON.bc_show.identifier.id,
        title: responseJSON.bc_show.title,
        show_title: responseJSON.bc_show.show_title,
        edit_title: responseJSON.bc_show.edit_title,
        method: "PUT",
        code: "edit_alert",
        edit_mode: edit_mode
      }
    });    

  }  
  _showContactSuccess(responseJSON, url, edit_mode) {
    if (responseJSON) {
      this.setState({
        url_contact: url,
        firstImages: {
          images: responseJSON.form.edit_contact.inputs.filter(
            (e) => e.id === "image"
          ),
        },
        formData: {
          images: responseJSON.form.edit_contact.inputs.filter(
            (e) => e.id === "image"
          ),
        },
        showModalForm: true,
        resources: responseJSON.assets,
        modalData: {
          idMain: this.props.balance_show.identifier.id,
          id: responseJSON.identifier.id,
          form: responseJSON.form.edit_contact,
          can_manage: responseJSON.form.edit_contact.can_manage,
          resource_key: responseJSON.form.edit_contact.resource_key,
          url: url,
          title: responseJSON.title,
          show_title: responseJSON.show_title,
          edit_title: responseJSON.edit_title,
          method: "PUT",
          code: "edit_contact",
          edit_mode: edit_mode
        },
      });
    } else {
      alert("Access error");
    }
  }

  _showContact(url, edit_mode = false) {
    this.props.getContact(
      this.props.authentication_token,
      (responseJSON) => this._showContactSuccess(responseJSON, url, edit_mode),
      () => alert("error"),
      url +
        "?resourceable_id=" +
        this.props.balance_show.identifier.resourceable_id +
        "&resourceable_type=" +
        this.props.balance_show.identifier.resourceable_type
    );
  }

  _destroyAssetAssociated(modalData) {
    let resource_key = ["owner_beneficiary"].includes(modalData.resource_key)
      ? modalData.code === "edit_owner"
        ? "owner_life_insurance"
        : "beneficiary_life_insurance"
      : modalData.resource_key;

    this.setState({
      showDeleteModal: true,
      modal_title: this.props.internalization.modals[resource_key].delete.title,
      modal_body: this.props.internalization.modals[resource_key].delete.body,
      modal_url: modalData.url,
      modal_action: () => this._successDestroyAssetAssociated(modalData),
    });
  }

  _successDestroyAssetAssociated(modalData) {
    let resource_key = ["owner_beneficiary"].includes(modalData.resource_key)
      ? modalData.code === "edit_owner"
        ? "owner_life_insurance"
        : "beneficiary_life_insurance"
      : modalData.resource_key;
    this.setState({
      destoyedSuccess: true,
      modal_title: this.props.internalization.modals[resource_key].deleted
        .title,
      modal_body: this.props.internalization.modals[resource_key].deleted.body,
      modal_action: () => this._fetch(0, modalData.resource_key),
    });
  }

  _destroyAssets(url, state) {
    let allAssets = state.resources;
    let deletedAssets = state.deletedAssets;
    let setKey;

    if (url.resourceable_id !== undefined) {
      setKey = "resourceable_id";
    } else {
      setKey = "id";
    }

    let searchAssets = allAssets.filter(
      (deleted) => deleted[setKey] === url[setKey]
    );

    if (searchAssets.length > 1) {
      allAssets.map((item, index) => {
        if (item.id === url.id && item.ownerable_type === url.ownerable_type) {
          allAssets.splice(index, 1);
        }
      });
    } else {
      allAssets = allAssets.filter(
        (deleted) => deleted[setKey] !== url[setKey]
      );
    }

    deletedAssets.push(url);
    this.setState({
      resources: allAssets,
      deletedAssets: deletedAssets,
    });
  }

  _navigateToAsset(state){
    let asset = state.asset
    let url = 
      asset.url_recover ? 
        asset.url_recover ? 
          asset.url.collection 
          : asset.url.collection
            : asset.url
    if(url.indexOf('ownership_structure') !== -1){
      window.location.href = asset.url.resource || url
    }else {
      let url_asset = url.slice()
      url_asset = url_asset.split("/")
      url_asset.pop()
      url_asset = url_asset.join("/")
      if(asset.resourceable_type === "Tenant"){
        url_asset = url.slice()
        url_asset = url_asset.split("/")
        let id = url_asset[url_asset.length - 1]
        url_asset.pop()
        url_asset = url_asset.join("/")
        this.props.history.push("balance-detail?url="+url_asset+"&id="+id, {
          url: url_asset,
          id: id
        });
      }
      else{
        this.props.history.push("balance-detail?url="+url_asset+"&id="+asset.resourceable_id, {
          url: url_asset,
          id: asset.resourceable_id
        });  
      }
      
      setTimeout(function(){
        window.location.reload()
      },200)
    }
    
    this.setState({
      showNavigateModal: false,
      showModal: false
    });
  }

  _navigateToAssetFromLinkedResource(item) {
    if (item.url_recover.indexOf("ownership_structure") !== -1) {
      this.props.history.push(item.url_recover + item.id);
    } else {
      let url_item = item.url_recover.slice();
      url_item = url_item.split("/");
      url_item.pop();
      url_item = url_item.join("/");
      this.props.history.push(
        "/balance-detail?url=" + url_item + "&id=" + item.id,
        {
          url: url_item,
          id: item.id,
        }
      );
    }

    setTimeout(function () {
      window.location.reload();
    }, 200);
  }

  _showDeleteLinkModal(item) {
    this.setState({
      showDeleteModalDocument: false,
      showModal: false,
      linkedDestroyModal: true,
      modal_title: this.props.internalization.modals.linked.delete.title,
      modal_body: this.props.internalization.modals.linked.delete.body,
      modal_action: () => this._deleteLinkedResource(item),
    });
  }

  _deleteLinkedResource(item) {
    this.props.deleteLinkedResource(
      this.props.authentication_token,
      this.props.balance_show.identifier.resourceable_type,
      this.props.balance_show.identifier.id,
      item.class_name,
      item.id,
      () => this._showSuccessDeleteLinkModal(),
      () => alert("error")
    );
  }

  _showSuccessDeleteLinkModal() {
    this.setState({
      showDeleteModalDocument: false,
      showModal: false,
      showSuccesModal: true,
      linkedDestroyModal: false,
      modal_title: this.props.internalization.modals.linked.deleted.title,
      modal_body: this.props.internalization.modals.linked.deleted.body,
      modal_action: () => this._fetch(),
    });
  }

  _deleteDocument(item, url){
    this.setState({
      showDeleteModalDocument: {
        title: this.props.internalization.alerts.delete_asset,
        subtitle: item.linked ? this.props.internalization.alerts.delete_document_linked : this.props.internalization.alerts.delete_asset_confirmation,
        url: url,
        method: "DELETE"
      }
    })
  }

  _successDeleteAssetDocument(){
    this.setState({
      showDeleteModalDocument: false,
      showModal:false,
      resources: [],
      showSuccesModal: true,
      showModalForm: false,
      //destoyedSuccess: true,
      modal_title: this.props.internalization.modals.document.deleted.title,
      modal_body: this.props.internalization.modals.document.deleted.body,
      modal_action: () => this._fetch(0, 'document')
    })
  }

  _onClickSecurity(id){
    let security_info = this.state.balance_show_state.security_info
    console.log(security_info[id])
    if(security_info[id] !== undefined){
      this.setState({showSecurityModal: security_info[id]})  
    }
    
  }

  _pieSelected(pie, nested){

    if(this.state.balance_show_state.breadcrumb_code === "collections") return;

    if (this.state.pieSelected && this.state.pieSelectedElement !== -1) {

      if(pieIndex != pie.index) {
        this.setState({pieSelected: pie, pieSelectedElement: pie.index})
        pieIndex = pie.index
      }else {
        this.setState({pieSelected: false, pieSelectedElement: -1})
      }

    }else {
      this.setState({pieSelected: pie, pieSelectedElement: pie.index})
      pieIndex = pie.index
    }
  };

  _successRecentOrderesActivity(responseJSON) {
    this.setState({
      activityRencentOrders: responseJSON.rows,
      totalPages: responseJSON.total_pages,
      currentPage: responseJSON.page,
      filters_internalization: responseJSON.internalization
    });
  }

  goToPage(params) {
    if (params !== "") {
      this.props.getRecentOrdersActivities(
        this.props.authentication_token,
        params,
        responseJSON => this._successRecentOrderesActivity(responseJSON),
        () => this._error()
      )
    }  
  }

  filteredCall(params) {
    this.goToPage(params)
  }  


  paginateTab(params, success, error) {
    params["resourceable_id"] = this.state.balance_show_state.identifier.resourceable_id
    params["resourceable_type"] = this.state.balance_show_state.identifier.resourceable_type
    this.props.paginateTab(this.props.authentication_token, params, (responseJSON) => {
      
      let current_show = this.state.balance_show_state
      if(Object.keys(responseJSON.tables).length === 0){
        current_show.tables_addepar[params["source"]]["table"]["rows"] = []
      }else{
        let prepare = {[params["source"]]: {table: responseJSON.tables[params["source"]]}}
        if(params["source"] === "top_10"){
          prepare = {[params["source"]]: responseJSON.tables[params["source"]]}
        }
        
        current_show.tables_addepar = {...current_show.tables_addepar, ...prepare}  
      }
      
      this.setState({balance_show_state: current_show})
      success();
    }, () => error(), false)
  }

  changeTabDropdown(tab, index) {

    if(tab !== null) {
      let code_selected = this._getUrlVars2()["code"]
      let new_path = window.location.search
      new_path = new_path.replace("&dropdown_code="+this.state.dropDownTabActive.code, "")
      new_path = new_path.replace("&code="+code_selected, "")
      this.props.location.search = new_path
      this.setState({dropDownTabActive: tab}); 
      this.props.getBreadcrumbs({
        url:
        this.props.history.location.pathname +
          new_path,
        assetUrl: "balance_principal/financial_assets/"+tab.code,
      });
      window.history.replaceState({}, "bt360", this.props.location.pathname + new_path + "&dropdown_code="+tab.code)
      this.props.location.search = this.props.location.pathname + new_path + "&dropdown_code="+tab.code

    }
  }

  getDropdownActiveIndex(dropdown_code = undefined) {
    return this.state.balance_show_state.content_menu.findIndex((tab) => tab.code === dropdown_code)
  }

  render() {
    console.log(this.state)
    return (
      <div>
        {this.state.loading && (
          <Loading
            text={this.props.internalization.loading}
            show={this.state.loading}
          />
        )}
        {!this.state.loading &&
          this.state.balance_show_state &&
          Object.keys(this.state.balance_show_state).length > 0 && (
            <div>
              {this.state.showModal && (
                <CustomModal
                  isShowed={this.state.showModal}
                  close={() => this.setState({ showModal: false })}
                >
                  <p className="headingH2 block-title">
                    {this.state.modalTitle}
                  </p>
                  <p className="body grey-dark">
                    {this.state.modalDescription}
                  </p>
                </CustomModal>
              )}
              <div className="main-wrapper has-dropdown-menu">


                <div style={{display: "flex", justifyContent: "space-between"}}>

                  <div style={{display: "flex", alignItems: "center"}}>
                    <h2 className="truncate headingH1 balance-show-title">

                      { this.state.dropDownTabActive ? this.state.dropDownTabActive.title : this.state.balance_show_state.title }

                    </h2>
                    {this.state.balance_show_state.breadcrumb_code === "financial_assets" && (
                      <div className="c-subtitle-highlighted" style={{marginTop: 10, marginLeft: 12}}>
                        <span className="c-subtitle-highlighted__text ">
                          {
                            this.state.dropDownTabActive ?
                              ""
                              :
                              this.props.internalization.all_ammounts
                          }
                        </span>
                      </div>
                    )}
                  </div>

                  {this.state.balance_show_state && this.state.balance_show_state.content_menu && this.state.balance_show_state.content_menu.length > 0 && (
                    <ContentDropdownMenu 
                      tabs={this.state.balance_show_state.content_menu} 
                      index={this.state.dropDownTabActive ? this.getDropdownActiveIndex(this.state.dropDownTabActive.code) : -1}
                      changeTab={(tab, index) => { 
                        this.changeTabDropdown(tab,index)
                      }}
                    />
                  )}

                </div>



                

                <ContentBlock
                  header={false}
                  maxHeight={false}
                  className="centered-and-full"
                >
                  {
                    this.state.balance_show_state.breadcrumb_code !== "financial_assets" &&
                    this.state.balance_show_state.tables &&
                    this.state.balance_show_state.tables.map((table, i) => {
                      return (
                        <div
                          key={`${i}-${table.title}`}
                          className={`wrapper-separator ${table.key === "overdue" ? 'overdue' : ''}`}
                        >
                          <TableBlock
                            key={`table-block-${table.title}`}
                            data={table}
                            history={this.props.history}
                            notScroll={
                              i === 0 &&
                              this.state.balance_show_state.id ===
                                "financial_assets"
                            }
                            footer={
                              this.state.balance_show_state.id ===
                              "financial_assets"
                                ? this.state.balance_show_state.footer
                                : ""
                            }
                            showModal={() => this.setState({ showModal: true })}
                            getModalContent={(title, description) =>
                              this._getModalContent(title, description)
                            }
                            noTruncate={
                              i === 0 &&
                              this.state.balance_show_state.id ===
                                "financial_assets"
                            }
                            pieSelectedElement={this.state.pieSelectedElement}
                            setLoading={(value) =>
                              this.setState({ loading: value })
                            }
                            language={this.props.user.language}
                          />
                        </div>
                      );
                    })}
                  {this.state.balance_show_state &&
                    this.state.balance_show_state.breadcrumb_code !==
                      "financial_assets" &&
                    this.state.balance_show_state.graphs.map((graph, i) => {
                      return (
                        <>
                        <p className="c-charts__bottom-text-title" style={{marginBottom:0, textAlign: 'center'}}>{graph.graph_data.title}</p>
                        <div
                          key={i}
                          style={{marginTop:0}}
                          className={
                            this.state.balance_show_state.graphs.length !==
                            i + 1
                              ? "wrapper-separator"
                              : ""
                          }
                        >
                          {graph.graph_type === "pie" &&
                            graph.graph_data.data.length > 0 && (
                              <PieHighcharts
                                style={{marginTop:0, paddingTop:0 }}
                                key={"_" + graph.graph_data.data[0].x}
                                // pieSelected={(element) => navigateTo(element.options, this.props)}
                                data={graph.graph_data.data}
                                pieSelected={(pie) =>
                                  i == 0 ? this._pieSelected(pie, false) : ""
                                }
                                from="balance-show"
                              />
                            )}
                        </div>
                        </>
                      );
                    })}
                    {
                      this.state.balance_show_state &&
                      this.state.balance_show_state.breadcrumb_code !==
                      "financial_assets" && this.state.balance_show_state.graphs_extra_real_estate &&
                      <div style={{display: 'flex'}}>
                      {
                        this.state.balance_show_state.graphs_extra_real_estate.map((graph, i) => {
                      return (
                        <div style={i == 0 ? {minWidth: 500, marginRight: 10} : {minWidth: 500} }>
                          <div>
                            <p className="c-charts__bottom-text-title" style={{marginBottom:0, textAlign: 'center'}}>{graph.graph_data.title}</p>
                          </div>
                          <div
                            style={{marginTop:0}}
                            key={"extra_"+i}
                            >
                            

                            {graph.graph_type === "pie" &&
                              graph.graph_data.data.length > 0 && (
                                <PieHighcharts
                                  size={180}
                                  style={{marginTop:0, paddingTop:0 }}
                                  key={"_" + graph.graph_data.data[0].x}
                                  // pieSelected={(element) => navigateTo(element.options, this.props)}
                                  data={graph.graph_data.data}
                                  pieSelected={(pie) =>
                                    i == 0 ? this._pieSelected(pie, false) : ""
                                  }
                                  from="balance-show"
                                />
                              )}
                          </div>
                        </div>
                      );
                    })}
                      </div>
                      
                    }

                  {this.state.balance_show_state.breadcrumb_code !==
                    "financial_assets" && (
                    <div className="o-width--100">
                      {Object.keys(this.props.balance_show).length > 0 &&
                        this.props.balance_show.linked_resources &&
                        this.props.balance_show.linked_resources.data.length >
                          0 && (
                          <DetailContentBlock
                            title={
                              this.props.balance_show.section_titles
                                .investment_accounts
                            }
                            paddingBottomSixteen={true}
                          >
                            <div className="block__box">
                              {this.props.balance_show.linked_resources.data.map(
                                (item, index) => {
                                  return (
                                    <BoxResources
                                      noMarginTop={true}
                                      edit={false}
                                      title={item.name || item.title}
                                      description={item.description}
                                      iconName={getIconAsset(item.iconName)}
                                      actionShow={() =>
                                        this.setState({
                                          linkedResource: item,
                                          showAlertAsk: true,
                                        })
                                      }
                                    />
                                  );
                                }
                              )}
                            </div>
                          </DetailContentBlock>
                        )}

                      {/* 
                      {this.props.balance_show.documents !== undefined &&
                        this.state.btnShowAllItems["documents"] &&
                        this.props.balance_show.documents.length > 0 && (
                          <DetailContentBlock
                            title={
                              this.props.balance_show.section_titles.documents
                            }
                          >
                            <div className="box-wrapper-color">
                              {this.props.balance_show.documents
                                .slice(
                                  0,
                                  this.state.btnShowAllItems["documents"]
                                    .lengthItems
                                )
                                .map((element, i) => {
                                  return (
                                    <div className="box-wrapper-color__content">
                                      <MediaBlock
                                        key={
                                          "documents_" + i + "_" + element.title
                                        }
                                        image={
                                          <RenderDocIcon
                                            sizeBig="true"
                                            type={element.type}
                                          />
                                        }
                                        imageSeparator={true}
                                        titleFullWidth={true}
                                        title={element.title}
                                        description={false}
                                        rightText={element.size}
                                        documentType={element.type}
                                        action={() =>
                                          this._showDocument(element.url)
                                        }
                                        center={true}
                                      />
                                    </div>
                                  );
                                })}
                            </div>
                            {this.props.balance_show.documents.length >
                              MAX_SHOW && (
                              <button
                                className="btn primary"
                                onClick={() =>
                                  this._btnShowAllItems(
                                    "documents",
                                    !this.state.btnShowAllItems["documents"]
                                      .show,
                                    this.props.balance_show.documents.length
                                  )
                                }
                                style={{ marginTop: 24 }}
                              >
                                <span
                                  className="btn-text"
                                  style={{ padding: "20px 0" }}
                                >
                                  {!this.state.btnShowAllItems["documents"].show
                                    ? "Show all Documents"
                                    : "Show less Documents"}
                                </span>
                              </button>
                            )}
                          </DetailContentBlock>
                        )}
                       */}
                      {/* 
                      {this.props.balance_show.contacts !== undefined &&
                        this.state.btnShowAllItems["contacts"] &&
                        this.props.balance_show.contacts.length > 0 && (
                          <DetailContentBlock
                            title={
                              this.props.balance_show.section_titles.contacts
                            }
                          >
                            <div className="box-wrapper-color">
                              {this.props.balance_show.contacts
                                .slice(
                                  0,
                                  this.state.btnShowAllItems["contacts"]
                                    .lengthItems
                                )
                                .map((element, i) => {
                                  return (
                                    <div className="box-wrapper-color__content">
                                      <MediaBlock
                                        key={
                                          "documents_" + i + "_" + element.title
                                        }
                                        image={
                                          <img
                                            src={
                                              element.avatar_url.url !== null
                                                ? element.avatar_url.url
                                                : avatars[
                                                    element.avatar_url
                                                      .avatar_default_id
                                                  ]
                                            }
                                            className="mediaBlock-image size-big"
                                            alt="contactImage"
                                          />
                                        }
                                        imageSeparator={true}
                                        titleFullWidth={true}
                                        title={element.title}
                                        description={false}
                                        rightText={element.size}
                                        documentType={element.type}
                                        action={() =>
                                          this._showContact(element.url)
                                        }
                                        center={true}
                                      />
                                    </div>
                                  );
                                })}
                            </div>
                            {this.props.balance_show.contacts.length >
                              MAX_SHOW && (
                              <button
                                className="btn primary"
                                onClick={() =>
                                  this._btnShowAllItems(
                                    "contacts",
                                    !this.state.btnShowAllItems["contacts"]
                                      .show,
                                    this.props.balance_show.contacts.length
                                  )
                                }
                                style={{ marginTop: 24 }}
                              >
                                <span
                                  className="btn-text"
                                  style={{ padding: "20px 0" }}
                                >
                                  {!this.state.btnShowAllItems["contacts"].show
                                    ? "Show all Documents"
                                    : "Show less Documents"}
                                </span>
                              </button>
                            )}
                          </DetailContentBlock>
                        )}
                       */}
                      {/*
                  this.state.balance_show_state.id === "financial_assets" && Object.keys(this.state.balance_show_state.graphs_select).length > 0 &&
                  <div style={{marginTop: 50}}>
                      <div className="filter-buttons-wrapper">
                        {this.state.balance_show_state.graphs_select.filters.map((item, i) => {
                          return (
                            <button
                              key={i}
                              onClick={() => {this._setPieColorsScaleFinancial(item);this.setState({active: item})}  }
                              className={`filter-buttons btn primary ${
                                this.state.active === item ? "border" : ""
                              }`}
                            >
                              <span
                                className="btn-text"
                              >
                                {this.state.balance_show_state.graphs_select.filters[i]}
                              </span>
                            </button>
                          );
                        })}
                      </div> 
                      <div>
                          <PieGraphic
                             key={"_"+this.state.active}
                            showTotal={false}
                            data={this.state.balance_show_state.graphs_select.graph[this.state.active]}
                            innerRadius={90}
                            centerLabel={true}
                            colorScale={this.state.pieColorsScale}
                            history={this.props.history}
                            from="balance-show"
                          />
                      </div>              
                  </div>
                */}
                    </div>
                  )}
                </ContentBlock>

                {/* DropDownTab -- begins*/}
                {this.state.dropDownTabActive && this.state.dropDownTabActive.code === "documents" && (
                  <div className="c-tabs__content is-selected">
                    <div className="c-tabs__content-top">
                      <Table
                        dataTable={this.state.balance_show_state.tables_addepar.documents.table}
                        showSearcher={
                          this.state.balance_show_state.tables_addepar.documents.table.total_pages !== 1 || (this.state.searchText["documents"] !== undefined && this.state.searchText["documents"] !== "")
                        }
                        fullWidth={true}
                        action={{
                          title: "Action",
                          show: (el) => this._showDocument(el.url, false),
                          edit: (el) => this._showDocument(el.url, true),
                        }}
                        searchText={this.state.searchText["documents"]}
                        search={(text) =>
                          this.paginateTab(
                            { text: text, source: "documents", page: 1, type: "get_documents" },
                            () => this.setState({ searchText: { ...this.state.searchText, ["documents"]: text } }),
                            () => ""
                          )
                        }
                        all_ellipsis={true}
                      />
                    </div>

                    {this.state.balance_show_state.tables_addepar.documents.table.rows.length > 0 &&
                     this.state.balance_show_state.tables_addepar.documents.table.total_pages > 1 && (
                        <div className="c-tabs__content-bottom">
                          <Pagination
                            goTo={(page) => {
                              this.paginateTab(
                                {
                                  text: this.state.searchText["documents"],
                                  source: "documents",
                                  page: page,
                                  type: "get_documents",
                                },
                                () => "",
                                () => ""
                              );
                              // goToPage(page)
                            }}
                            pages={Array.from(
                              { length: this.state.balance_show_state.tables_addepar.documents.table.total_pages },
                              (v, k) => k + 1
                            )}
                            currentPage={this.state.balance_show_state.tables_addepar.documents.table.current_page}
                          />
                        </div>
                      )}
                  </div>
                )}

                
                {this.state.dropDownTabActive && this.state.dropDownTabActive.code === "alerts" && (
                  <div className="c-tabs__content is-selected">
                    <div className="c-tabs__content-top">
                      <Table
                        dataTable={this.state.balance_show_state.tables_addepar.alerts.table}
                        showSearcher={
                          this.state.balance_show_state.tables_addepar.alerts.table.total_pages !== 1 || (this.state.searchText["alerts"] !== undefined && this.state.searchText["alerts"] !== "")
                        }
                        fullWidth={true}
                        action={{
                          title: "Action",
                          show: (el) => this._showAlert(el.id, false),
                          edit: (el) => this._showAlert(el.id, true),
                        }}
                        searchText={this.state.searchText["alerts"]}
                        search={(text) =>
                          this.paginateTab(
                            { text: text, source: "alerts", page: 1, type: "get_alerts" },
                            () => this.setState({ searchText: { ...this.state.searchText, ["alerts"]: text } }),
                            () => ""
                          )
                        }
                        all_ellipsis={true}
                      />
                    </div>

                    {this.state.balance_show_state.tables_addepar.alerts.table.rows.length > 0 &&
                     this.state.balance_show_state.tables_addepar.alerts.table.total_pages > 1 && (
                        <div className="c-tabs__content-bottom">
                          <Pagination
                            goTo={(page) => {
                              this.paginateTab(
                                {
                                  text: this.state.searchText["alerts"],
                                  source: "alerts",
                                  page: page,
                                  type: "get_alerts",
                                },
                                () => "",
                                () => ""
                              );
                              // goToPage(page)
                            }}
                            pages={Array.from(
                              { length: this.state.balance_show_state.tables_addepar.alerts.table.total_pages },
                              (v, k) => k + 1
                            )}
                            currentPage={this.state.balance_show_state.tables_addepar.alerts.table.current_page}
                          />
                        </div>
                      )}
                  </div>
                )}

                {this.state.dropDownTabActive && this.state.dropDownTabActive.code === "contacts" && (
                  <div className="c-tabs__content is-selected">
                    <div className="c-tabs__content-top">
                      <Table
                        dataTable={this.state.balance_show_state.tables_addepar.contacts.table}
                        showSearcher={
                          this.state.balance_show_state.tables_addepar.contacts.table.total_pages !== 1 || (this.state.searchText["contacts"] !== undefined && this.state.searchText["contacts"] !== "")
                        }
                        fullWidth={true}
                        action={{
                          title: "Action",
                          show: (el) => this._showContact(el.url, false),
                          edit: (el) => this._showContact(el.url, true),
                        }}
                        searchText={this.state.searchText["contacts"]}
                        search={(text) =>
                          this.paginateTab(
                            { text: text, source: "contacts", page: 1, type: "get_contacts" },
                            () => this.setState({ searchText: { ...this.state.searchText, ["contacts"]: text } }),
                            () => ""
                          )
                        }
                        all_ellipsis={true}
                      />
                    </div>

                    {this.state.balance_show_state.tables_addepar.contacts.table.rows.length > 0 &&
                     this.state.balance_show_state.tables_addepar.contacts.table.total_pages > 1 && (
                        <div className="c-tabs__content-bottom">
                          <Pagination
                            goTo={(page) => {
                              this.paginateTab(
                                {
                                  text: this.state.searchText["contacts"],
                                  source: "contacts",
                                  page: page,
                                  type: "get_contacts",
                                },
                                () => "",
                                () => ""
                              );
                              // goToPage(page)
                            }}
                            pages={Array.from(
                              { length: this.state.balance_show_state.tables_addepar.contacts.table.total_pages },
                              (v, k) => k + 1
                            )}
                            currentPage={this.state.balance_show_state.tables_addepar.contacts.table.current_page}
                          />
                        </div>
                      )}
                  </div>
                )}

                {/*this.state.dropDownTabActive && this.state.dropDownTabActive.code === "financial_statements" && (
                  <div className="c-tabs__content is-selected">
                    <div className="c-tabs__content-top" style={{marginBottom: 20}}>
                      <TableDropdown
                        // props.history.push("/financial-statements")
                        key={"financial_statements" + this.state.pieSelectedElement}
                        selectedIndex={this.state.pieSelectedElement}
                        dataTable={this.state.balance_show_state.tables_addepar.financial_statements.table.table}
                        // pieSliceSelected={pieSliceSelected}
                        // search={(text) => console.log(text)}
                        history={this.props.history}
                        isFinancial={true}
                        isStatement={true}
                        action={{
                          title: "Acciones", 
                          show: (el) => this._showDocument(el.doc_url, false),
                        }} 
                      />
                    </div>
                    <div className="c-tabs__content-top">
                      <TableDropdown
                        // props.history.push("/financial-statements")
                        key={"financial_statements" + this.state.pieSelectedElement}
                        selectedIndex={this.state.pieSelectedElement}
                        dataTable={this.state.balance_show_state.tables_addepar.financial_statements.table.table_2}
                        // pieSliceSelected={pieSliceSelected}
                        // search={(text) => console.log(text)}
                        history={this.props.history}
                        isFinancial={true}
                        isStatement={true}
                        action={{
                          title: "Acciones", 
                          show: (el) => this._showDocument(el.doc_url, false),
                        }} 
                      />
                    </div>

                  </div>
                )*/}




                {/* DropDownTab -- ends */}

                { this.state.balance_show_state.tables && 
                  this.state.balance_show_state.breadcrumb_code === "financial_assets" && 
                  !this.state.dropDownTabActive && (
                    <FinancialAssetsTabs
                      cleanStatementNotification={() => this.props.postCleanNotificationsAdeppar(this.props.authentication_token)}                    
                      temporal_user_ids={this.props.user.id}
                      fetchFromHistorical={(date) => this._fetchFromHistorical(date)}
                      location={this.props.location}
                      history={this.props.history}
                      tableBlock={this.state.balance_show_state.tables[0]}
                      consolidated_graph={this.state.balance_show_state.consolidated_graph}
                      dataTable={this.state.balance_show_state.tables_addepar}
                      recentOrder={this.state.activityRencentOrders}
                      filters_internalization={
                        this.state.filters_internalization
                      }
                      showPagination={(value) =>
                        this.setState({ showPagination: value })
                      }
                      filteredCall={(params) => this.filteredCall(params)}
                      currentPage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      setStep={(step) => this.setState({ step: step })}
                      showDocument={(url, edit_mode) => this._showDocument(url, edit_mode)}
                      showContact={(url, edit_mode) => this._showContact(url, edit_mode)}
                      showAlert={(id, edit_mode) => this._showAlert(id, edit_mode)}
                      tab_index={this.state.tab_index}
                      paginateTab={(params,success, error) => this.paginateTab(params, success, error)}
                      unlinkContact={(contact) => this._unlinkContact(contact)}
                      language={this.props.user.language}
                      onClickSecurity={(id) => this._onClickSecurity(id)}
                      security_info={this.state.balance_show_state.security_info}
                      date={this.state.date}
                      getFinancialStatements={(params, success, error) => this.props.getFinancialStatements(this.props.authentication_token, (responseJSON) => success(responseJSON), () => error(), params)}
                      financial_statements_more_data={this.state.balance_show_state.financial_statements_more_data}
                      props={this.props}
                    />
                )}
              </div>
            </div>
          )}

        {this.state.showModalForm === true && (
          <AssetsResourcesModal
            categorieOptions={this.state.balance_show_state.form}
            successSendFiles={() => {this.setState({
                  showModalForm: false,
                  resources: {},
                  deletedAssets: [],
                }, this._fetch(0, 'document'))
            }}
            language={this.props.user.language}
            identifier={this.props.balance_show.identifier}
            colorGrey={true}
            data={this.state.modalData}
            errors={this.props.errors}
            closeModal={(e) => {
              if (
                e.target.className === "modal-wrapper" ||
                (e.target.closest(".modal-header") !== null &&
                  e.target
                    .closest(".modal-header")
                    .className.includes("modal-header"))
              ) {
                this.setState({
                  showModalForm: false,
                  resources: {},
                  deletedAssets: [],
                });
              }
            }}
            uploadResource={(data) => this._uploadResource(data)}
            resources={this.state.resources}
            goToResource={(asset) => this._goToResource(asset)}
            polymorphic={
              this.state.modalData.resource_key === "contact"
                ? "resourceable_"
                : "ownerable_"
            }
            resourceToSelectDestroy={true}
            resourceDestroyAction={(url) =>
              this._destroyAssets(url, this.state)
            }
            destroyAssetAssociated={() =>
              this._destroyAssetAssociated(this.state.modalData)
            }
            deleteDocument={(url) =>
              this.state.modalData.resource_key === "document"
                ? this._deleteDocument(
                    this.state.modalData,
                    this.state.modalData.url
                  )
                : ""
            }
            buttons={this.props.internalization.buttons}
            showModalSessionExpire={(time_left, diffMins, checkTime) =>
              this.props.showModalSessionExpire(time_left, diffMins, () =>
                checkTime()
              )
            }
            getLinkForm={(url, success_fromblock, error_fromblock) =>
              this.props.getLinkForm(
                url,
                this.props.authentication_token,
                (responseJSON) => success_fromblock(responseJSON),
                () => error_fromblock("error")
              )
            }
          />
        )}

        {this.state.showModalAddContact && (
          <Modal isShowed={this.state.showModalAddContact}>
            <ViewList assign={true} assignData={this.state.assignData} />
          </Modal>
        )}

        {this.state.showAlertAsk && (
          <AlertModal
            isShowed={this.state.showAlertAsk}
            title={
              this.props.insurance_user
                ? this.props.internalization.alerts.navigate_to_asset
                : this.props.internalization.alerts.navigate_to_or_delete
            }
            // msg={"Mensaje"}
            action={() => {
              this.setState({ showAlertAsk: false });
              this._navigateToAssetFromLinkedResource(
                this.state.linkedResource
              );
            }}
            textButton={this.props.internalization.alerts.navigate_to}
            textButtonAlertColor={false}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={false}
            actionCancelButton={() => this.setState({ showAlertAsk: false })}
            thirdButton={this.props.insurance_user ? false : true}
            textButtonAlertColorThird={true}
            textButtonThird={this.props.internalization.buttons.unlink}
            actionThirdButton={() => {
              this.setState({ showAlertAsk: false });
              this._showDeleteLinkModal(this.state.linkedResource);
            }}
          />
        )}
        {this.state.linkedDestroyModal && (
          <AlertModal
            isShowed={this.state.linkedDestroyModal}
            title={this.state.modal_title}
            msg={this.state.modal_body}
            action={() => {
              this.state.modal_action();
            }}
            textButton={this.props.internalization.buttons.ok}
            textButtonAlertColor={true}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={false}
            actionCancelButton={() => {
              this.setState({
                linkedDestroyModal: false,
                showModal: false,
                showDeleteModal: false,
                destoyedSuccess: false,
              });
            }}
          />
        )}
          {this.state.showUnlink && (
            <AlertModal
              isShowed={this.state.showUnlink}
              title={this.state.modal_title}
              msg={this.state.modal_body}
              action={() => {
                this.state.modal_action()
              }}
              textButton={this.props.internalization.buttons.ok}
              textButtonAlertColor={true}
              cancelButton={true}
              cancelTextButton={this.props.internalization.buttons.cancel}
              cancelButtonAlertColor={false}
              actionCancelButton={() => {
                this.setState({
                  showUnlink: false
                });
              }}
            />
          )}

        {Object.keys(this.state.showDeleteModalDocument).length > 0 && (
          <AlertModal
            isShowed={
              Object.keys(this.state.showDeleteModalDocument).length > 0
            }
            title={this.props.internalization.modals.document.delete.title}
            msg={this.props.internalization.modals.document.delete.body}
            action={() => {
              !this.state.loading &&
                this.setState(
                  {
                    showDeleteModal: false,
                    loading: true,
                  },
                  () =>
                    this.props.destroyAsset(
                      this.props.authentication_token,
                      () => this._successDeleteAssetDocument(),
                      () => alert("error"),
                      this.state.showDeleteModalDocument.url,
                      this.state.showDeleteModalDocument.method
                    )
                );
            }}
            textButton="Yes"
            textButtonAlertColor={true}
            cancelButton={true}
            cancelTextButton="Cancel"
            cancelButtonAlertColor={false}
            actionCancelButton={() => {
              this.setState({
                showDeleteModalDocument: {},
              });
            }}
          />
        )}
        {this.state.destoyedSuccess && (
          <AlertModal
            isShowed={this.state.destoyedSuccess}
            title={this.state.modal_title}
            msg={this.state.modal_body}
            action={() => {
              this.state.modal_action();
            }}
            textButton={this.props.internalization.buttons.ok}
          />
        )}

        {this.state.showDeleteModal && (
          <AlertModal
            isShowed={this.state.showDeleteModal}
            title={this.state.modal_title}
            msg={this.state.modal_body}
            action={() => {
              this.props.destroyAsset(
                this.props.authentication_token,
                () => this.state.modal_action(),
                () => alert("error"),
                this.state.modal_url,
                "DELETE"
              );
              this.setState({
                showDeleteModal: false,
                showModalForm: false,
              });
            }}
            textButton={this.props.internalization.buttons.ok}
            textButtonAlertColor={true}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={false}
            actionCancelButton={() => {
              this.setState({
                showDeleteModal: false,
              });
            }}
          />
        )}

        {this.state.showSuccesModal && (
          <AlertModal
            isShowed={this.state.showSuccesModal}
            title={this.state.modal_title}
            msg={this.state.modal_body}
            action={() => {
              this.setState({
                showSuccesModal: false,
              });
              this._fetch(0, this.state.modalData.resource_key);
            }}
            textButton={this.props.internalization.buttons.ok}
          />
        )}

        {this.state.showNavigateModal && (
          <AlertModal
            isShowed={this.state.showNavigateModal}
            title={this.props.internalization.alerts.navigate_to_asset}
            msg={
              this.props.internalization.alerts.navigate_to +
              " " +
              this.state.asset.name
            }
            action={() => {
              this._navigateToAsset(this.state);
            }}
            textButton={this.props.internalization.buttons.ok}
            textButtonAlertColor={false}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={true}
            actionCancelButton={() => {
              this.setState({
                showNavigateModal: false,
              });
            }}
          />
        )}

        {this.state.balance_show_state &&
          this.state.balance_show_state.actions &&
          this.state.balance_show_state.actions.length > 0 && 
          (this.state.balance_show_state.has_all_statements_alerts == false && this.state.step === "financial_statements" || this.state.step !== "financial_statements") && (
            <>
              <ActionSheet
                ref={this.actionSheet}
                render={this.state.options_action_sheet}
              />
              <FloatButton
                children={
                  <Icon
                    name="Close"
                    color="white"
                    iconBig={true}
                    className="wrapper-icon-floatButton"
                    close={false}
                  />
                }
                action={() =>
                  this._showActionSheetContent(
                    this.state,
                    this.props.balance_show.form.hasOwnProperty(
                      "add_life_insurance"
                    )
                  )
                }
              />
            </>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    balance_show: state.balance.balance_show,
    balance_url_show: state.balance.balance_url_show,
    authentication_token: state.session.authentication_token,
    internalization: state.session.internalization,
    errors: state.session.errors,
    user: state.session.user,
    enabled_assign_documents: state.session.enabled_assign_documents,
    enabled_assign_contacts: state.session.enabled_assign_contacts,
    insurance_user: state.session.insurance_user,
    bulltick_insurance_agency: state.session.bulltick_insurance_agency,
    real_user_insurance: state.session.real_user_insurance,
    market_closed: state.session.market_closed,
    total_pages: state.notifications.total_pages,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFinancialStatements: (authentication_token, success, error, params) =>
      dispatch(getFinancialStatements(authentication_token, success, error, params)),    
    postCleanNotificationsAdeppar: (authentication_token) => dispatch(postCleanNotificationsAdeppar(authentication_token)),    
    getAlert:(authentication_token, id, success, error) => dispatch(getAlert(authentication_token, id, success, error)),    
    paginateTab: (authentication_token, params, success, error, dispatch_enabled) => dispatch(paginateTab(authentication_token, params, success, error, dispatch_enabled)),
    getBCShow: (authentication_token, historical_date, success, error, url) =>
      dispatch(getBCShow(authentication_token, historical_date, success, error, url)),
    getBCShowForms: (authentication_token, success, error, url) =>
      dispatch(getBCShowForms(authentication_token, success, error, url)),      
    destroyAssignAssetByResourceable: ( authentication_token, success, error, id,resourceable_id, resourceable_type, url, type) =>
      dispatch( destroyAssignAssetByResourceable( authentication_token, success, error, id, resourceable_id, resourceable_type, url, type)),
    createAssetResource: ( authentication_token, success, error, url, method, attributes) => dispatch( createAssetResource( authentication_token, success, error,url, method, attributes)),
    getDocDetail: (authentication_token, success, error, url) =>
      dispatch(getDocDetail(authentication_token, success, error, url)),
    getContact: (authentication_token, success, error, url) =>
      dispatch(getContact(authentication_token, success, error, url)),
    destroyAsset: (authentication_token, success, error, url, method) =>
      dispatch(destroyAsset(authentication_token, success, error, url, method)),
    deleteLinkedResource: ( authentication_token, type_1, mid_1, type_2, id_2, success,error ) => dispatch( deleteLinkedResource( authentication_token, type_1, mid_1,type_2, id_2, success, error)),
    setEnabledAssignContacts: () => dispatch(setEnabledAssignContacts()),
    setEnabledAssignDocuments: () => dispatch(setEnabledAssignDocuments()),
    getRecentOrdersActivities: (authentication_token, page, url, success, error) =>
      dispatch(
        getRecentOrdersActivities(authentication_token, page, url, success, error)
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceShowScreen);
