// Dependencies
import React from "react";
import { connect } from "react-redux";
import { DETAIL_TYPE_ONE_CODES, NOT_PASSIVES } from "../../constants";

// Actions
import { editAsset, editValuation } from "../../actions/balance";
import { destroyImageAsset } from "../../actions/image";
import { destroyTenants } from '../../actions/tenant'
import { getLinkForm, getConvertedValue, getCapitalAmortizationValue } from "../../actions/formblock";

//Components
import Loading from "../../components/loading";
import AlertModal from "../../components/alertModal";
import FormBlock from "../../components/formBlock";

// Variables
let data = {};

class BalanceEditScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showSuccesModal: false,
      alertModal: false, 
      modal_title: "",
      modal_body: "",
      modal_action: ""
    };
    data = this.props.history.location.state;
  }

  _success() {
    let detail_type;
    let reg = new RegExp(NOT_PASSIVES);
    if (this.props.history.location.state.resource_key === "valuation") {
      detail_type = "valuation";
    } else {
      detail_type = reg.test(this.props.history.location.state.url)
        ? "asset"
        : "liability";
    }

    this.setState({
      loading: false,
      showSuccesModal: true,
      modal_title: this.props.internalization.modals[detail_type].updated.title,
      modal_body: this.props.internalization.modals[detail_type].updated.body,
      modal_action: () => this.props.history.goBack()
    });
  }

  _error(responseJSON) {
    if(responseJSON && responseJSON.hasOwnProperty("error")){
      this.setState({
        loading: false,
        showSuccesModal: true,
        modal_title: "Error",
        modal_body: responseJSON.error,
        modal_action: () => this.setState({showSuccesModal: false})
      });
    }
    else{
      this.setState({loading: false})
      alert("Error");  
    }
  }
  _getLocalStorageCurrentBreadcrumb() {
    let current_breadcrumb_localstorage = localStorage.getItem(
      "current_breadcrumb"
    );
    if (current_breadcrumb_localstorage) {
      try {
        current_breadcrumb_localstorage = JSON.parse(
          current_breadcrumb_localstorage
        );
      } catch (e) {
        current_breadcrumb_localstorage = [];
      }
    } else {
      current_breadcrumb_localstorage = [];
    }
    return current_breadcrumb_localstorage;
  }
  _updateEditedAsset(formData) {
    this.setState({loading: true})
    if (this.props.history.location.state.resource_key === "valuation") {
      this.props.editValuation(
        this.props.authentication_token,
        () => this._success(),
        () => this._error(),
        data.url,
        data.method,
        { [data.resource_key]: formData }
      );
    } else {
      if(formData.hasOwnProperty("is_sold")){
        let is_sold = data.form.filter((e) => e.id == "is_sold")[0]
        if(is_sold.value !== formData["is_sold"] && (formData["is_sold"] == "Sí" || formData["is_sold"] == "Yes")){
          this.setState({
            alertModal: true,
            modal_title: this.props.internalization.modals['sold'].title,
            modal_body: this.props.internalization.modals['sold'].body,
            modal_action: () => this._editAsset(formData)
          });
        }else{
          this._editAsset(formData)
        }
        
      }else{
        this._editAsset(formData)
      }
      
    }
  }
  _editAsset(formData) {
    this.props.editAsset(
        this.props.authentication_token,
        () => this._success(),
        (responseJSON) => this._error(responseJSON),
        data.url,
        data.method,
        { [data.resource_key]: formData }
      );
  }
  _destroyTenants(ids) {
    this.props.destroyTenants(this.props.authentication_token, ids, this.props.history.location.state.resource_id , this.props.history.location.state.resource_key, () => {
      this.setState({
        showSuccesModal: true,
        modal_title: this.props.internalization.modals['tenant'].deleted.title,
        modal_body: this.props.internalization.modals['tenant'].deleted.body,
        modal_action: () => ""
      });

    }, () => alert("Error"))
    
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
    this.props.setSpecialParent(this._getLocalStorageCurrentBreadcrumb());
    this.props.getBreadcrumbs({
      url:
        this.props.history.location.pathname +
        this.props.history.location.search,
      assetUrl: this.props.history.location.pathname,
    });
  }

  render() {

    return (
      <div className="main-wrapper white">
        {this.state.loading && (
          <Loading
            text={this.props.internalization.loading}
            show={this.state.loading}
          />
        )}
        {data !== undefined && (
          <FormBlock
            language={this.props.user.language}
            colorGrey={true}
            data={data}
            errors={this.props.errors}
            actionSubmitButton={(formData) => this._updateEditedAsset(formData)}
            deleteImage={(id) =>
              this.props.destroyImageAsset(
                this.props.authentication_token,
                () => "",
                () => "",
                id
              )
            }
            loadMultiple={true}
            buttons={this.props.internalization.buttons}
            getLinkForm={(url, success_fromblock, error_fromblock) =>
              this.props.getLinkForm(
                url,
                this.props.authentication_token,
                (responseJSON) => success_fromblock(responseJSON),
                () => error_fromblock("error")
              )
            }
            getConvertedValue={(value, currency_type, date, success, error) => this.props.getConvertedValue(value, currency_type, date, this.props.authentication_token, (responseJSON) => success(responseJSON),() => error("error"))}
            getCapitalAmortizationValue={(data, success, error) => this.props.getCapitalAmortizationValue(data, this.props.authentication_token, (responseJSON) => success(responseJSON),() => error("error"))}
            resource_key={this.props.history.location.state.resource_key}
            resource_id={this.props.history.location.state.resource_id}
            destroyTenants={(ids) => this._destroyTenants(ids)}
          />
        )}

        {this.state.showSuccesModal && (
          <AlertModal
            isShowed={this.state.showSuccesModal}
            title={this.state.modal_title}
            msg={this.state.modal_body}
            action={() => {
              this.setState({
                showSuccesModal: false,
              });
              this.state.modal_action()
            }}
            textButton={this.props.internalization.buttons.ok}
          />
        )}
        {this.state.alertModal && (
          <AlertModal
            isShowed={true}
            title={this.state.modal_title}
            msg={this.state.modal_body}          
            action={() => {
              this.setState({
                alertModal: false,
              });
              this.state.modal_action()
            }}
            textButton={this.props.internalization.buttons.ok}
            textButtonAlertColor={false}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={true}
            actionCancelButton={() => {
              this.setState({
                alertModal: false,
              });
            }}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    errors: state.session.errors,
    internalization: state.session.internalization,
    user: state.session.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getConvertedValue: (value, currency_type, date, authentication_token, success, error) => dispatch(getConvertedValue(value, currency_type, date, authentication_token, success, error)),
    getCapitalAmortizationValue: (data, authentication_token, success, error) => dispatch(getCapitalAmortizationValue(data, authentication_token, success, error)),
    getLinkForm: (url, authentication_token, success, error) =>
      dispatch(getLinkForm(url, authentication_token, success, error)),
    editAsset: (
      authentication_token,
      success,
      error,
      url,
      method,
      attributes
    ) =>
      dispatch(
        editAsset(authentication_token, success, error, url, method, attributes)
      ),
    editValuation: (
      authentication_token,
      success,
      error,
      url,
      method,
      attributes
    ) =>
      dispatch(
        editValuation(
          authentication_token,
          success,
          error,
          url,
          method,
          attributes
        )
      ),
    destroyImageAsset: (authentication_token, success, error, id) =>
      dispatch(destroyImageAsset(authentication_token, success, error, id)),
    destroyTenants: (authentication_token, ids, resourceable_id, resourceable_type, success, error) => 
      dispatch(destroyTenants(authentication_token, ids, resourceable_id, resourceable_type, success, error)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceEditScreen);
