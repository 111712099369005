export function canManage(permits, key){
	if(permits && Object.keys(permits).length > 0 ){
		if(key === "rest_assets" || key === "fixed_asset"){
			return Object.values(permits.manage).every(value => value === true);
		}else{
			if(key === "private_equity"){
				return permits.manage["direct_investment"]			
			}else{
				key = prepareKey(key)
				return permits.manage[""+key]									
			}
			
		}
		
	}
	return true
}

export function canRead(permits, key){
	if(permits && Object.keys(permits).length > 0 ){
		if(key === "rest_assets" || key === "fixed_asset"){
			return Object.values(permits.read).every(value => value === true);
		}else{
			if(key === "private_equity"){
				return permits.read["direct_investment"]			
			}else{
				key = prepareKey(key)
				return permits.read[""+key]									
			}
		}
		
	}
	return true	
}

function prepareKey(key){
	if (key === "liability") return "liabilities"
	if (key === "receivable_account") return "account_receivable"	
	if (key === "deferred_asset") return "life_insurance"
	if (key === "financial_asset") return "financial_assets"
	if (key === "others") return "other"	
	return key		
}

export function convertClasses(class_name){
	if (class_name == "RealEstate")  return "real_estate" 
	if (class_name == "ReceivableAccount") return "direct_investment" 
	if (class_name == "PrivateEquity") return "private_equity" 
	if (class_name == "Vehicle") return "vehicle" 
	if (class_name == "Art") return "art" 
	if (class_name == "Watch") return "watch" 
	if (class_name == "BankAccount") return "bank_account" 
	if (class_name == "LifeInsurance") return "life_insurance" 
	if (class_name == "Other") return "others" 
	if (class_name == "Liability") return "liabilities" 
	if (class_name == "LiabilityAddepar") return "liabilities" 
	if (class_name == "Trust") return "ownership" 
	if (class_name == "Corporation") return "ownership" 
	if (class_name == "InvestmentAccount") return "ownership" 
	if (class_name == "BeneficiaryAccount") return "ownership" 
	if (class_name == "User") return "personal" 
	if (class_name == "StakeHolder")  return "stake_holder" 
	if (class_name == "FinancialAssetAssociated") return "financial_asset_associateds" 
	if (class_name == "Custodian") return "custodian_asset_associateds" 
	if (class_name == "Account") return "direct_investment" 
	if (class_name == "Tenant") return "real_estate" 
	if (class_name == "AlternativeAsset") return "alternative_asset" 
}
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
